import { ContainerSection } from "components/common/table/styled";
import React from "react";
import { useHistory, useParams } from "react-router";
import { TableComponent } from "modules/product/components/rawMaterial/components/tableMaterial/TableComponent";
import { Routes } from "AppRouter";
import styled from "styled-components";
import { VendorModel } from "services/external/firebase/models/VendorsModel";

interface VendorsTableData {
  name: string
  country: string;
  productsMade: number;
  id: string;
}

interface Props {
  vendorList:  VendorModel[]
}

export function VendorsTable({
  vendorList
}: Props): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const navigateToVendorPage = (vendorId: string) => {
    if (history) {
      history.push(Routes.VENDOR.replace(":id", vendorId!));
    }
  };

  const dataTable: VendorsTableData[]  = React.useMemo<VendorsTableData[]>(
    () => vendorList.map(v => {
      return ({
        name: v.name,
        country: v.country,
        productsMade: 123,
        id: v.id
    })}),
    [id, vendorList]
  )

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Vendor',
        accessor: 'name', 
        style:{
          width: '10%',
        }
      },
      {
        Header: 'Code',
        accessor: 'code',
        style:{
          width: '10%',
        }
      },
      {
        Header: 'Country',
        accessor: 'country',
        style:{
          width: '20%',
        }
      },
      {
        Header: 'Products Made',
        accessor: 'productsMade',
        style:{
          width: '50%',
          textAlign: 'right'
        }
      },
      {
        Header: 'ID',
        accessor: 'id'
      },
    ],
    []
  )
 
  return (
   
      <StyledContainerSection>
      {vendorList.length > 0 && 
        <TableComponent 
          onRowClick={(vendor) => navigateToVendorPage(vendor.id)}
          hasItemOptions={false}
          data={dataTable}
          columns={columns} />
        }
        {vendorList.length == 0 &&
        <EmptyContainer>There are no vendors</EmptyContainer>
        }
      </StyledContainerSection>

  );
}

const StyledContainerSection = styled(ContainerSection)`
  margin-top: 1rem;
`

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.unabacusblack};
  min-height: 300px;
  font-size: 18px;
  background: #ffffff;
  margin: 1rem;
  font-style: bold;
`;
