import { useState } from "react";

interface Props {
  title: string;
  activeTab: string;
  onClick: (name: string) => void;
}

export default function TabElement({ title, activeTab, onClick }: Props): JSX.Element {
  const [] = useState<boolean>(false);
  let className = 'tab-list-item';

    if (activeTab === title) {
      className += ' tab-list-active';
    }
  return (
    <li
      className={className}
      onClick={() => onClick(title)}
    >
      {title}
    </li>
  );
}
