import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import styled from "styled-components";
import firebase from "firebase";
import { LoadingElement } from "components/common/LoadingElement";
import { ImagePlaceHolder } from "styles/assets";

interface Props {
  onUploadDone: (fileName: string) => void;
  imagePath?: string;
}

const downloadImage = (imageUri: string): Promise<string> => {
  const storage = firebase.storage();
  const pathReference = storage.ref(imageUri);
  return pathReference.getDownloadURL().then((url) => {
    return url;
  });
};

const uploadImage = (storageUri: string, file: File): Promise<string> => {
  const storageRef = firebase.storage().ref();
  const mountainsRef = storageRef.child(storageUri);
  return mountainsRef.put(file).then((snapshot) => snapshot);
};

export const ProductInformationImage: React.FC<Props> = ({
  onUploadDone,
  imagePath,
}) => {
  const { id } = useParams<{ id: string }>();
  const [storageImagePath, setStorageImagePath] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!imagePath) {
      setIsLoading(true);
      const displayImage = async () => {
        const downloadedImageUri = await downloadImage(imagePath);
        setStorageImagePath(downloadedImageUri);
        setIsLoading(false);
      };
      displayImage();
    }
  }, []);

  const onFileChange = async (event) => {
    setIsLoading(true);
    const storageUri = `miansai/${id}.jpg`;
    const file = event.target.files[0];
    uploadImage(storageUri, file).then(async () => {
      onUploadDone(storageUri);
      const downloadedImageUri = await downloadImage(storageUri);
      setStorageImagePath(downloadedImageUri);
      setIsLoading(false);
    });
  };

  return (
    <>
      <label>Product Image</label>
      <StyledContainer className="outerContainer">
        <ImageContainer>
          {isLoading ? (
            <LoadingElement pageCentered={true} />
          ) : (
            <>
              <span />
              <img src={storageImagePath || ImagePlaceHolder} />
              <input type="file" onChange={onFileChange} />
            </>
          )}
        </ImageContainer>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  margin: 1rem 0;
  background-color: ${(props) => props.theme.colors.backgroundGreyLight};
  min-width: 260px;

  .outerContainer {
    display: flex;
    justify-content: center;
    height: 100%;
  }
  span {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    width: 260px;
  }

  > input[type="file"] {
    height: 100%;
    cursor: pointer;
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
`;
