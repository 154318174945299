import { Dropdown, OnChangeData } from "carbon-components-react";
import { Material, MaterialAPI, Plating } from "type";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import CsvModalCheckBox from "./CsvModalCheckBox";

export interface PartToImport {
  isSelected: boolean;
  layerName: string;
  volume: string;
  baseMaterial?: MaterialAPI;
  platingMaterial?: Plating;
  surface: string;
}

interface Props {
  value: Material;
  onUpdate: (partToImport: PartToImport) => void;
}

export default function ModalSquare({
  value,
  onUpdate
}: Props): JSX.Element {

  const [Materials, setMaterials] = useState<MaterialAPI[]>([]);
  const [SelectedMaterial] = useState<Material | null>(
    null
  );

  const [plating, setPlating] = useState<Plating[]>([]);
  const [SelectedPlating] = useState<Material | null>(null);


  const[partToImport, setPartToImport] = useState<PartToImport>({
    isSelected: false,
    layerName: value.LayerName,
    volume: value.Volume,
    surface: value.Area || '',
    baseMaterial: undefined,
    platingMaterial: undefined,
  })

  const firebase = useFirebaseContext();

  useEffect(() => {
    firebase.getAPIMaterials().then((materials) => {
      setMaterials(materials);
    });
  }, []);

  useEffect(() => {
    firebase.getPlating().then((platings) => {
      setPlating(platings);
    });
  }, []);

  
  useEffect(() => {
    onUpdate(partToImport);
  }, [partToImport]);

  return (
    <Container onClick={() =>setPartToImport({...partToImport, isSelected: !partToImport.isSelected})}>
      <CsvModalCheckBox selected={partToImport.isSelected} />
      <ContainerData>
        <ItemField>
          <ItemKey>Part</ItemKey>
          <ItemValue>{value.LayerName}</ItemValue>
        </ItemField>
        <ItemField>
          <ItemKey>Volume</ItemKey>
          <ItemValue>{value.Volume} mm3</ItemValue>
        </ItemField>
        <ItemField>
          <ItemKey>Base Material</ItemKey>
          <StyledDropdownContainer>
          <DropdownStyled
            label={SelectedMaterial ? SelectedMaterial.Name : "Choose"}
            onChange={(data: OnChangeData) =>
              setPartToImport({...partToImport, baseMaterial: Materials.find(material => material.Name === data.selectedItem) || undefined})
            }
            id="materials"
            items={Materials.sort((a,b) => a.Name > b.Name ? 1 : -1).map((material) => material.Name)}
            titleText=""
          />
          </StyledDropdownContainer>
        </ItemField>
        <ItemField>
          <ItemKey>Surface</ItemKey>
          <ItemValue>{value.Area} mm3</ItemValue>
        </ItemField>
        
        <ItemField>
          <ItemKey>Plating</ItemKey>
          <StyledDropdownContainer>
          <DropdownStyled
            label={SelectedPlating ? SelectedPlating.Name : "Choose"}
            onChange={(data: OnChangeData) =>
              setPartToImport({...partToImport, platingMaterial: plating.find(material => material.name === data.selectedItem) || undefined})
            }
            id="Plating"
            items={plating.sort((a,b) => a.name > b.name ? 1 : -1).map((plating) => plating.name)}
            titleText=""
          />
          </StyledDropdownContainer>
        </ItemField>
      </ContainerData>
    </Container>
  );
}

const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid black;
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const ItemField = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3rem 0;
`;

const ItemKey = styled.p`
  font-size: 15px;
  width: 6rem;
  margin-right: 30px;
`;

const ItemValue = styled.p`
  font-size: 18px;
`;

const DropdownStyled = styled(Dropdown)`
  width: 170px;
  position: relative;
  .bx--list-box__menu {
    width: 170px;
    position: fixed;
    left: auto;
    right: auto;
  }
`;

const StyledDropdownContainer = styled.div `
  margin-bottom:10px;
`
