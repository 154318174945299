import React from "react";

import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/elements/buttons/Button";
import { FormInputContainer } from "components/common/FormInputContainer";
import { DropDown } from "components/common/Dropdown";
import Form from "components/common/Form";
import { vendorInformationsSchema } from "./VendorInformationValidation";
import { countryList } from "core/data/countries";
import styled from "styled-components";


interface VendorInformationForm {
  name: string;
  country: string;
}

interface VendorInformationFormsInterface {
  defaultValue: VendorInformationForm;
}

export const VendorInformationForms: React.FC<VendorInformationFormsInterface> = (
  props
) => {
  const firebase = useFirebaseContext();
  const { id } = useParams<{ id: string }>();

  const onSubmit = (values: VendorInformationForm) => {
    firebase.VendorService.updateVendor(id, {
      name: values.name,
      country: values.country,
      id
    });
  };
  
  return (
    <Form validationSchema={yupResolver(vendorInformationsSchema)} onSubmit={onSubmit}>
      <FormInputContainer
        type="text"
        name="name"
        placeholder=""
        label="Name"
        value={props.defaultValue.name}
        black={true}
      />
      <DropDown
        value={props.defaultValue.country}
        label="Country"
        name="country"
        selectors={countryList.map((country) => ({
          name: country,
          value: country
        }))}
        black={true}
      />
      <ActionBar>
        <Button type="submit">Submit</Button>
      </ActionBar>
    </Form>
  );
};

const ActionBar = styled.span`
  display: flex;
  flex-direction: row;
  button:first-child, div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  div {
    background-color: ${(prop) => prop.theme.colors.mainColorLight};
    color: ${(prop) => prop.theme.colors.mainColor};
  }
`