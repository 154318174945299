import React, { useState } from "react";

import { CSVModal } from "modules/product/components/csv/CsvModal";
import { Material, MaterialType } from "type";
import { CSVImport } from "./ProductCsvImport";

export interface File {
  data: string[];
}
export function CSVImportComponent(): JSX.Element {
  const [ImportedData, setImportedData] = useState<Material[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCSVImport = (param: File[]) => {
    const list: Material[] = [];
    const keys = param[0].data;
    param.splice(0, 1);
    param.forEach(function (value) {
      const obj: Material = {
        Name: "",
        Weight: "",
        BasePrice: "",
        TotalPrice: "0.00",
        Type: MaterialType.BASE_MATERIAL,
        Volume: "",
        Category: "",
        LayerName: "",
        ID: "",
        Area: ""
      };

      if (value.data.length === keys.length) {
        value.data.forEach(function (datacsv, idx) {
          // Transform keys imported from rhino and exclude the unused ones
          const key = transformKey(keys[idx]) as keyof Material;
          if (Object.keys(obj).includes(key)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (obj[key] as any) = datacsv;
          }
        });
        list.push(obj);
      }
    });
    setImportedData(list);
    setIsOpen(true);
  };

  const transformKey = (param: string) => {
    if (param.includes("Object")) {
      return param.split(" ")[1];
    } else if (param.includes("Layer")) {
      return "LayerName";
    } else if (param.includes("Area")) {
      return "Area";
    } else {
      return param.split(" ")[0];
    }
  };

  return (
    <>
      <CSVImport handleImport={handleCSVImport} />
      <CSVModal isOpen={isOpen} setOpen={setIsOpen} data={ImportedData} />
    </>
  );
}
