import { Dropdown, DropdownSkeleton, OnChangeData } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';

import { Add20 } from '@carbon/icons-react';
import { ModalWrapper } from '../../../../components/common/modals/ModalWrapper';
import { Routes } from 'AppRouter';
import { TextInput } from '../../../../components/common/modals/TextInput';
import styled from 'styled-components';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useHistory } from 'react-router';
import { CategoryFirestore } from 'services/external/firebase/models/CategoryFirestore';
import { ProductFirestore } from 'services/external/firebase/models/ProductFirestore';
import { LaborDifficultyType } from '../labor/components/calculation/CalculationDefinition';
import { CostType } from 'services/domain/products/models/ProductModel';

const initialValue: ProductFirestore = {
  name: '',
  sku: '',
  category: '',
  categoryName: '',
  cost: '0',
  id: '',
  costType: CostType.AverageCost,
  cogs: {
    packaging: '0',
    materials: '0',
    labor: '0',
  },
  productParts: {},
  misc: {},
  labor: {
    type: LaborDifficultyType.EASY,
    cost: 0,
    calculationMethod: 'percentage',
  },
  averageCost: 0,
  marketPrice: {
    retailPrice: 0,
    useDefaultCalculation: true,
    wholesalePrice: 0,
  },
  specification: {},
};

export function AddProductModal(): JSX.Element {
  const history = useHistory();
  const firebase = useFirebaseContext();
  const [categories, setCategories] = useState<CategoryFirestore[]>([]);
  const [state, setstate] = useState<ProductFirestore>(initialValue);

  useEffect(() => {
    getCategoriesFromFirebase();
  }, []);

  const getCategoriesFromFirebase = async () => {
    const fetchedCategories = await firebase.getCategories();
    setCategories(fetchedCategories);
  };
  const handleSubmit = async () => {
    const res = await firebase.ProductService.createProduct(state);
    history.push(Routes.PRODUCT.replace(':id', res.id));
  };

  const handleChange = (param: string | null | undefined) => {
    if (param) {
      const index = categories.findIndex((element) => element.name === param);
      setstate({ ...state, category: categories[index].id });
    }
  };

  const button = (
    <ContainerHeaderTitle>
      <Add20 />
      <p>Add Product</p>
    </ContainerHeaderTitle>
  );

  return (
    <ModalWrapper handleSubmit={handleSubmit} button={button} title="Add Product">
      <StyledModalContent>
        <TextInput label="Product name" handleChange={(e: string) => setstate({ ...state, name: e })} defaultValue={state.name} />
        <TextInput label="SKU" handleChange={(e: string) => setstate({ ...state, sku: e })} defaultValue={state.sku} />
        {categories.length > 1 ? (
          <StyledDropdownContainer>
            <StyledDropdown
              onChange={(data: OnChangeData) => handleChange(data.selectedItem)}
              id="category"
              items={categories.map((value) => value.name)}
              label="Select Category"
              defaultValue={categories[0].name}
              titleText="Select Category"
            />
          </StyledDropdownContainer>
        ) : (
          <DropdownSkeleton />
        )}
      </StyledModalContent>
    </ModalWrapper>
  );
}

const StyledModalContent = styled.div`
  min-width: 300px;
  > * {
    margin-bottom: 10px;
  }
`;

const StyledDropdownContainer = styled.div`
  margin-bottom: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  position: absolute;
  width: 90%;
`;

const ContainerHeaderTitle = styled.div`
  width: 140px;
  height: 40px;
  border: 1px solid unabacusblack;
  text-align: center;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #efefef;

  p {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
`;
