import React, { ReactNode, useState } from "react";
import { Collapse } from "react-collapse";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  title: string;
  icon?: JSX.Element
}

export default function Collapser({ children, title, icon }: Props): JSX.Element {
  const [state, setstate] = useState<boolean>(true);
  return (
    <ContainerCollapse>
      <TitleCollapse>
        <div className="icon-title">
          {icon}
          <Title>{title}</Title>

        </div>
        <CollapseButton onClick={() => setstate(!state)}>
          {!state ? "Expand" : "Collapse"}
        </CollapseButton>
      </TitleCollapse>
      <Collapse isOpened={state}>
        <CollapseContent>{children}</CollapseContent>
      </Collapse>
    </ContainerCollapse>
  );
}

const ContainerCollapse = styled.div`
  width: 100%;
  height: auto;
`;

const TitleCollapse = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  .icon-title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const Title = styled.div`
  color: #000;
  font-size: 20px;
`;

const CollapseButton = styled.div`
  color: grey;
  font-size: 17px;

  &:hover {
    cursor: pointer;
  }
`;

const CollapseContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
