import styled, { css } from "styled-components";

const styleInput = css<{ error?: boolean; black?: boolean, highlightValue?: boolean }>`
  border: 0;
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding-left: 10px;
  font-weight: ${(props) => props.highlightValue ? "bold" : "normal"};
  background-color: ${(props) =>
    props.black ? props.theme.colors.backgroundGreyLight : "white"};

  ${(props) =>
    props.error &&
    css`
      border: 1px solid red;
    `}
  &:focus {
    outline: none;
  }
`;

export const Input = styled.input<{ error?: boolean; black?: boolean; highlightValue?: boolean }>`
  ${styleInput}
`;