// Use app context to provide firebase function (in `old` file)
import { useAppContext } from "core/contexts/AppContext";
import React, { createContext, useContext, useState } from "react";
import { FirebaseUtility } from "../../services/external/firebase/FirebaseUtility";

type Ctx = FirebaseUtility;

export type InitializeFirebaseContext = Ctx | null;

export type InitializedFirebaseContext = Ctx;

const FirebaseContext = createContext<InitializeFirebaseContext>(null);

export const FirebaseContextProvider: React.FC = (props) => {
  const { firebase } = useAppContext();
  const [firebaseCtx] = useState(new FirebaseUtility(firebase!));

  return (
    <FirebaseContext.Provider value={firebaseCtx}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export function useFirebaseContext(): InitializedFirebaseContext {
  return useContext(FirebaseContext)!;
}
