import React from "react";
import ReactDOM from "react-dom";

interface Prop {
    children: React.ReactNode;
}

export default class BodyEnd extends React.Component {
  el: HTMLDivElement;
  constructor(props: Prop) {
      super(props);
      this.el = document.createElement('div');
      this.el.style.display = 'contents';
  }
  
  componentDidMount() : void {
      document.body.appendChild(this.el);
  }

  componentWillUnmount() : void {
      document.body.removeChild(this.el);
  }
  
  render() : React.ReactPortal {
      return ReactDOM.createPortal(
          this.props.children,
          this.el,
      );
  }
}