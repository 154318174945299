import { AppLoader } from "components/common/AppLoader";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthStatus, useUserHooks, UseUserHooks } from "../hooks/user/useUserHooks";

export type UserContextLoading = UseUserHooks | null;

const UserContext = createContext<UserContextLoading>(null);

export type UserContextLoaded = UseUserHooks;

export function useUserContext(): UserContextLoaded {
  return useContext(UserContext)!;
}

export const UserProvider: React.FC = (props) => {
  const userHooks = useUserHooks();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (userHooks.authStatus !== AuthStatus.LOADING) {
      setIsReady(true);
    }
  }, [userHooks.authStatus]);

  return (
    <UserContext.Provider value={userHooks}>
      <AppLoader
        id={2}
        minimumLoadingTime={0}
        mandatoryProcesses={[{ name: "user", isReady }]}
      >
        {props.children}
      </AppLoader>
    </UserContext.Provider>
  );
};
