import { MaterialModel } from 'services/domain/products/models/ProductModel';

export type CalculationMethodType = 'percentage' | 'custom';

export interface CalculationMethod {
  id: CalculationMethodType;
  displayName: string;
}

export const CalculationMethodDefinition: CalculationMethod[] = [
  {
    id: 'percentage',
    displayName: 'Method 1 - Percentage',
  },
  {
    id: 'custom',
    displayName: 'Method 2 - Custom',
  },
];

export interface LaborDifficultyDefinition {
  id: string;
  name: string;
  type: LaborDifficultyType;
  weightPercentageRules: LaborDifficultyMap[];
}

export interface LaborDifficultyMap {
  minWeightGram: number;
  maxWeightGram: number;
  percentage: number;
}

export enum LaborDifficultyType {
  EASY = 'Easy',
  AVERAGE = 'Average',
  HARD = 'Hard',
}

// export const calculateByDifficulty = (baseMaterials: MaterialModel[], difficultyMethod: LaborDifficultyType): number => {
//   let totalPrice = 0;
//   const difficultyRules = DifficultyMethodDefinition.find((difficulty) => difficulty.type === difficultyMethod);
//   if (difficultyRules) {
//     totalPrice = baseMaterials.reduce((acc, current) => {
//       const currentWeight = parseFloat(current.weight);
//       const currentPrice = parseFloat(current.totalPrice);
//       const { percentage } = difficultyRules.weightPercentageRules.find(
//         ({ minWeightGram, maxWeightGram }) => currentWeight >= minWeightGram && currentWeight <= maxWeightGram
//       );
//       return acc + (currentPrice * percentage) / 100;
//     }, 0);
//   }

//   return totalPrice;
// };

export const DifficultyMethodDefinition: LaborDifficultyDefinition[] = [
  {
    id: '1',
    name: 'Easy',
    type: LaborDifficultyType.EASY,
    weightPercentageRules: [
      {
        minWeightGram: 0,
        maxWeightGram: 5,
        percentage: 70,
      },
      {
        minWeightGram: 5.01,
        maxWeightGram: 10,
        percentage: 60,
      },
      {
        minWeightGram: 10.01,
        maxWeightGram: 20,
        percentage: 45,
      },
      {
        minWeightGram: 20.01,
        maxWeightGram: Number.MAX_SAFE_INTEGER,
        percentage: 25,
      },
    ],
  },
  {
    id: '2',
    name: 'Average',
    type: LaborDifficultyType.AVERAGE,
    weightPercentageRules: [
      {
        minWeightGram: 0,
        maxWeightGram: 5,
        percentage: 80,
      },
      {
        minWeightGram: 5.01,
        maxWeightGram: 10,
        percentage: 67,
      },
      {
        minWeightGram: 10.01,
        maxWeightGram: 20,
        percentage: 55,
      },
      {
        minWeightGram: 20.01,
        maxWeightGram: Number.MAX_SAFE_INTEGER,
        percentage: 35,
      },
    ],
  },
  {
    id: '3',
    name: 'Hard',
    type: LaborDifficultyType.HARD,
    weightPercentageRules: [
      {
        minWeightGram: 0,
        maxWeightGram: 5,
        percentage: 90,
      },
      {
        minWeightGram: 5.01,
        maxWeightGram: 10,
        percentage: 75,
      },
      {
        minWeightGram: 10.01,
        maxWeightGram: 20,
        percentage: 65,
      },
      {
        minWeightGram: 20.01,
        maxWeightGram: Number.MAX_SAFE_INTEGER,
        percentage: 45,
      },
    ],
  },
];
