import { useEffect, useState } from "react";
import appFirebase from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export interface UseFirebaseHooks {
  firebase: appFirebase.app.App | null;
}

// TODO do more tests here
export function useFirebaseHooks(): UseFirebaseHooks {
  const [firebase, setFirebase] = useState<UseFirebaseHooks["firebase"]>(null);

  useEffect(() => {
    if (!appFirebase.apps.length) {
      setFirebase(appFirebase.initializeApp(firebaseConfig));
   } else {
    appFirebase.app(); 
   }

    return () => {
      firebase?.delete();
    };
  }, []);

  return { firebase };
}
