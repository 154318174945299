import {
  AppContextProvider,
  useAppContextSubscriber,
} from "./core/contexts/AppContext";
import { AppLoader, LoadingProcess } from "./components/common/AppLoader";
import { AppThemeProvider, GlobalStyle } from "./styles/theme/theme";

import AppRouter from "./AppRouter";
import { FirebaseContextProvider } from "./core/contexts/FirebaseContext";
import React, { useState } from "react";
import { ToastNotification } from "carbon-components-react";

function App(): JSX.Element {
  const appContext = useAppContextSubscriber();
  const [displayNotification, setDisplayNotification] = useState<boolean>(
    false
  );

  const mandatoryProcess: LoadingProcess[] = [
    { name: "firebase", isReady: appContext?.firebase ? true : false },
  ];

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <AppLoader
        id={1}
        minimumLoadingTime={process.env.NODE_ENV === "development" ? 0 : 0}
        mandatoryProcesses={mandatoryProcess}
      >
        <AppContextProvider value={appContext}>
          {displayNotification && (
            <ToastNotification
              caption="00:00:00 AM"
              kind="success"
              iconDescription="describes the close button"
              subtitle={
                <span>
                  Subtitle text goes here. <a href="#example">Example link</a>
                </span>
              }
              timeout={0}
              title="Notification title"
            />
          )}
          <FirebaseContextProvider>
            <AppRouter />
          </FirebaseContextProvider>
        </AppContextProvider>
      </AppLoader>
    </AppThemeProvider>
  );
}

export default App;
