import { useEffect, useState } from "react";

import { companyName } from "../../../../services/external/firebase/FirebaseUtility";
import { useAppContext } from "core/contexts/AppContext";
import { VendorModel } from "services/external/firebase/models/VendorsModel";

interface UseGetVendorByIdSnapshot {
  vendor: VendorModel;
}

export function useGetVendorByIdSnapshot(
  id: string
): UseGetVendorByIdSnapshot {
  const { firebase } = useAppContext();
  const [vendor, setVendor] = useState<VendorModel>();

  useEffect(() => {
    const unsubscribe = firebase!
      .firestore()
      .collection("clients")
      .doc(companyName)
      .collection("Vendors")
      .doc(id)
      .onSnapshot(function (querySnapshot) {
        const data = querySnapshot.data() as VendorModel;
        setVendor(data);
      });
    return () => unsubscribe();
  }, []);

  return { vendor };
}
