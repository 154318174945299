import { useFirebaseContext } from "core/contexts/FirebaseContext";
import React, { useEffect, useState } from "react";
import { MaterialModel } from "services/domain/products/models/ProductModel";
import { Material, MaterialType } from "type";
import { ProductBase } from "../../../../forms/SearchField";
import { AddProductPartModal } from "./addProductPartModal";

interface Props {
  layerName: string;
  data: MaterialModel[];
  docId: string;
  callback: () => void;
  isVisible: boolean;
  onClose: () => void;
}

export function AddPlating({
  layerName,
  data,
  docId,
  callback,
  onClose,
  isVisible
}: Props): JSX.Element {
    const firebase = useFirebaseContext();
    const [productList, setProductList] = useState<ProductBase[]>([]);

    useEffect(() => {
      firebase?.getPlating().then((res) => {
        const product: ProductBase[] = res.map(r => ({Name: r.name, Price: r.price}));
        setProductList(product);
      });
    }, []);

  return (
    <>
      <AddProductPartModal
        callback={callback}
        docId={docId}
        data={data}
        layerName={layerName}
        productType={MaterialType.PLATING}
        productList={productList}
        onClose={onClose}
        isVisible={isVisible} />
     </>
  );
}
