import { Add24 } from '@carbon/icons-react';
import React from 'react';
import styled from 'styled-components';

interface Props {
    children: string;
    onClick?: () => void
}

export function AddButton({ children, onClick }: Props): JSX.Element{
	return (
		<IconContainer onClick={onClick}>
			<IconText>{children}</IconText>
			<Add24 color="#fff" />
		</IconContainer>
	);
}

const IconContainer = styled.div`
  background-color: #353535;
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;

  &:hover {
    background-color: #2e2e2e;
    cursor: pointer;
  }
`;

const IconText = styled.p`
    color: #fff;
    font-size: 13px;
    padding: 0 10px;
`;
