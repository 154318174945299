import React from "react";
import styled from "styled-components";

import { TextInput } from "../../../../components/common/modals/TextInput";

export interface ProductBase {
  Name:string;
  Price:string
}

interface Props {
  handleChange: (searchTerm: string) => void;
}

export default function SearchField({
  handleChange,
}: Props): JSX.Element {

  return (
    <Container>
      <TextInput
        handleChange={handleChange}
        label=""
        placeholder="Search"
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;
