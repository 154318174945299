import React from 'react';

import styled from 'styled-components';
import { Modal } from 'components/common/modals/Modal';

interface Props {
  title: string;
  onDelete: () => void;
  onClose: () => void;
  isVisible: boolean;
}

export function DeleteModal({ onDelete, isVisible, onClose, title }: Props): JSX.Element {
  const handleSubmit = () => {
    onDelete();
    closeModal();
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal show={isVisible} onHide={() => closeModal()} title={title} handleSubmit={handleSubmit} noFooter={true}>
      <ButtonPrimary onClick={handleSubmit}>Yes</ButtonPrimary>
      <ButtonSecondary onClick={closeModal}>No</ButtonSecondary>
    </Modal>
  );
}

const ButtonPrimary = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.mainColor};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 40px;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.mainColor};
  margin-top: 10px;
  color: ${(props) => props.theme.colors.unabacusblack};
`;
