/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export const defaultIfNullOrEmpty = (str: string, defaultChar = ""): string => {
  if (!str) {
    return defaultChar;
  }
  return str;
};
