/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useForm } from "react-hook-form";

interface FormProps<T> {
 defaultValues?: T;
 children: JSX.Element[] | any;
 onSubmit: any;
 validationSchema: any;
}

export default function Form<T>({ children, onSubmit, validationSchema } : FormProps<T>): JSX.Element {
  const { register, handleSubmit, errors } = useForm<
  Partial<T>
>({
  resolver: validationSchema,
});
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {React.Children.map(children, child => {
        return child.props?.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                errors : errors,
                key: child.props.name,
              }
            })
          : child;
       })}
    </form>
  );
}
