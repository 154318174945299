import styled from "styled-components";

export const StyledLoader = styled.div`
    margin: 6px 0px 2px;
    justify-content: center;
    display: flex;
    vertical-align: middle;


    .bp3-spinner .bp3-spinner-track {
        stroke: var(--color-loader, white});
        opacity: .2;
    }

    .bp3-spinner .bp3-spinner-head {
        stroke: var(--color-loader, white);
        opacity: .8;
    }
`;

export const StyledLoadingMessage = styled.div`
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
`;

export const StyledWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
`;
