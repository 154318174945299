import { ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const WhiteContainer = styled.div`
  background: white;
  padding: 15px;
  max-width: auto;
  width: 290px;
  flex-direction: column;
  display: flex;
`;

interface Prop {
  children: ReactNode;
}

export const ContainerComponent = ({ children }: Prop): JSX.Element => {
  return (
    <StyledContainer>
      <WhiteContainer>{children}</WhiteContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: left;

  .subtitle {
    width: 260px;
    font-style: oblique;
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
