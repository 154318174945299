import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import styled from 'styled-components';
import firebase from 'firebase';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { Button } from 'components/elements/buttons/Button';

interface Props {
  imagePath?: string;
}

interface StorageImage {
  name: string;
  downloadUri: string;
}

const downloadImage = async (imageUri: string): Promise<StorageImage> => {
  const storage = firebase.storage();
  const pathReference = storage.ref(imageUri);
  const downloadUri = await pathReference.getDownloadURL();
  return {
    name: pathReference.name,
    downloadUri: downloadUri,
  };
};

const uploadImage = (storageUri: string, file: File): Promise<string> => {
  const storageRef = firebase.storage().ref();
  const mountainsRef = storageRef.child(storageUri);
  return mountainsRef.put(file).then((snapshot) => snapshot);
};

export const SpecificationUpload: React.FC<Props> = ({ imagePath }) => {
  const firebaseCtx = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const [storageImage, setStorageImage] = useState<StorageImage>();

  useEffect(() => {
    console.log(`image path ${imagePath}`);
    if (!!imagePath) {
      const displayImage = async () => {
        const downloadedImage = await downloadImage(imagePath);
        setStorageImage(downloadedImage);
      };
      displayImage();
    }
  }, []);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const storageUri = `miansai/product-specifications/${fileName}`;

    uploadImage(storageUri, file).then(async () => {
      const downloadedImage = await downloadImage(storageUri);
      firebaseCtx.ProductService.updateSpecificationImagePath(id, storageUri);
      setStorageImage(downloadedImage);
    });
  };

  return (
    <>
      <StyledContainer className="outerContainer">
        {storageImage ? (
          <>
            <a href={storageImage.downloadUri}>{storageImage.name}</a>
            <DocumentContainer>
              <Button style={{ width: '100%' }}>
                Browse and replace
                <input style={{ opacity: 0, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }} type="file" onChange={onFileChange}></input>
              </Button>
            </DocumentContainer>
          </>
        ) : (
          <DocumentContainer>
            <Button style={{ width: '100%' }}>
              Browse...
              <input style={{ opacity: 0, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }} type="file" onChange={onFileChange}></input>
            </Button>
          </DocumentContainer>
        )}
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const DocumentContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
  position: relative;
  margin: 1rem 0;
  min-width: 260px;

  > input[type='file'] {
    content: 'Browse...';
  }
`;
