import { createContext, useContext } from "react";
import {
  UseFirebaseHooks,
  useFirebaseHooks,
} from "../hooks/firebase/useFirebaseHooks";

export type InitializeAppContext = UseFirebaseHooks | null;

export type InitializedAppContext = UseFirebaseHooks;

const AppContext = createContext<InitializeAppContext>(null);

export const AppContextProvider = AppContext.Provider;

export function useAppContext(): InitializedAppContext {
  return useContext(AppContext)!;
}

export function useAppContextSubscriber(): InitializeAppContext {
  const { firebase } = useFirebaseHooks();

  return {
    firebase,
  };
}
