import { useFirebaseContext } from 'core/contexts/FirebaseContext'
import React, { useEffect, useRef } from 'react'
import { Row } from 'react-table'
import { Misc } from 'type'
import { TableComponent } from '../tableMaterial/TableComponent'
import { StyledMiscPartView } from './MiscPartView.styles'
import { MiscViewHeader } from './MiscViewHeader'

interface Props {
    title: string;
    data: Misc[];
    docId: string;
    productId: string;
    callback: () => void;
  }

export function MiscPartView({
  title,
  data,
  docId,
  productId,
  callback
}: Props): JSX.Element {
const firebase = useFirebaseContext();

const stateRef = useRef<Misc[]>();

useEffect(() => {
  stateRef.current = data;
}, [data])

const dataTable = React.useMemo(
  () => data.map(d => ({
    name: d.name,
    quantity: d.quantity,
    totalPrice: d.totalPrice.toFixed(2),
    unitPrice: d.unitPrice.toFixed(2),
    id: d.id
  })),
  [data]
);

const handleChange = (index: number, id: string, value: string) => {
  if(isNaN(parseFloat(value))) {
    console.log(`${value} is not a valid number`);
    return false;
  } 
  stateRef.current[index][id] = value;
  firebase.ProductService.updateMiscs(productId, docId,  stateRef.current)
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columns: any = React.useMemo(
  () => [
    {
      Header: 'Name',
      
      accessor: 'name', 
      style:{
        width: '77.5%',
      }
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      isEditable: true,
      style:{
        width: '7.5%',
        'text-align': 'right'
      }
    },
    {
      Header: 'Unit Price',
      subHeader: '$',
      accessor: 'unitPrice',
      style:{
        width: '7.5%',
        'text-align': 'right'
      }
    },
    {
      Header: 'Total Price',
      subHeader: '$',
      accessor: 'totalPrice',
      style:{
        width: '7.5%',
        'text-align': 'right'
      }
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
  ],
  []
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onDelete = (row: Row<any>) => {
  data = data.filter(d=> d.id != row.values.id);
  firebase.ProductService.updateMiscs(productId, docId, data)
}

return (
  <StyledMiscPartView>
    <MiscViewHeader callback={callback} docId={docId} data={data} LayerName={title}>
      {title ? title : "Add Misc"}
    </MiscViewHeader>
    {data.length > 0 && 
        <TableComponent 
          updateMyData={(index, id, value) => handleChange(index, id, value)}
          data={dataTable}
          hasItemOptions={true}
          columns={columns} 
          onDelete={onDelete}/>
        }
  </StyledMiscPartView>
  );
}
