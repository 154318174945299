import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { PiecePriceBreakdown } from 'type';

import { Calculator32, CrossTab32 } from '@carbon/icons-react/es';

import { CalculatorDetails } from './CalculatorDetails';
import { useGetProductTotalPriceV2 } from 'core/hooks/firebase/products/useGetProductTotalPriceV2';

export function StickyCalculatorComponent(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const price = useGetProductTotalPriceV2(id);

  const [displayed, setDisplayed] = useState(false);

  const [details, setDetails] = useState<PiecePriceBreakdown>();

  useEffect(() => {
    const { totalBaseMaterialPrice, totalPlatingPrice, totalMiscPrice, totalLaborPrice } = price;
    setDetails({
      materials: {
        baseMaterialTotal: totalBaseMaterialPrice,
        platingTotal: totalPlatingPrice,
        miscTotal: totalMiscPrice,
      },
      laborTotal: totalLaborPrice,
      packaging: '0',
    });
  }, [price]);

  return (
    <Container>
      <div>
        <Hint>Global Cost</Hint>
        {!!price?.totalPrice && <Price>{price.totalPrice.toFixed(2)}$ / Piece</Price>}
      </div>
      <ContainerIcon onClick={() => setDisplayed(!displayed)}>
        {displayed && details ? (
          <>
            <CrossTab32 color="white" />
            <CalculatorDetails details={details} />
          </>
        ) : (
          <Calculator32 color="white" />
        )}
      </ContainerIcon>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: black;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`;

const ContainerIcon = styled.div`
  width: 60px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.unabacusblack};
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const Hint = styled.p`
  font-size: 10px;
  color: white;
`;

const Price = styled.p`
  margin-top: 0;
  font-size: 28px;
  font-weight: 700;
`;
