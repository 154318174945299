export const vendorStatus = [
	"Files Sent",
	"Pending 1st Sample",
	"Received 1st Sample",
	"1st Sample Approved",
	"Pending 2nd Sample",
	"Received 2nd Sample",
	"2nd Sample Approved",
	"Pending 3rd Sample",
	"Received 3rd Sample",
	"3rd Sample Approved",
	"Vendor Approved",
	"Vendor Dropped",
	"Development Dropped",
];