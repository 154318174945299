import { TableHead, TableRow } from "carbon-components-react";

import styled from "styled-components";

export const ContainerSection = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionHeader = styled.div`
  height: 80px;
  width: 100%;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
`;

export const RowTable = styled(TableRow)`
  background-color: #fff !important;
`;

export const HeaderTable = styled(TableHead)`
  background-color: #fff;
`;
