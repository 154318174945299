import styled from 'styled-components';
import { useState } from 'react';

interface Prop {
  text: string;
  hoverText: string;
  onClick?: () => void;
}

export const HoverTextButton = ({ text, hoverText, onClick }: Prop): JSX.Element => {
  const [displayText, setDisplayText] = useState(text);
  return (
    <>
      <Button onClick={onClick} onMouseEnter={() => setDisplayText(hoverText)} onMouseLeave={() => setDisplayText(text)}>
        {displayText}
      </Button>
    </>
  );
};

const Button = styled.div`
  background-color: rgba(120, 61, 229, 0);
  border: ${(prop) => `solid ${prop.theme.colors.mainColor} 1px`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 100%;
  color: ${(prop) => prop.theme.colors.mainColor};
  &:hover {
    cursor: pointer;
    background-color: rgba(120, 61, 229, 0.25);
  }
`;
