import Close20 from '@carbon/icons-react/lib/close/20';
import React from 'react';
import styled from 'styled-components';

interface Props {
    title: string | null;
    onHide: () => void;
}

export default function ModalHeader({ title, onHide }: Props): JSX.Element {
    return (
        <Container>
            <Title>{title}</Title>
            <Icon onClick={onHide} />
        </Container>
    );
}

const Container = styled.div`
    padding: 20px 10px;
    width: '100%';
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
`;

const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    font-family: 'Roboto Bold', 'Roboto', sans-serif;
    padding-left: 10px;
`;

const Icon = styled(Close20)`
    &:hover {
        cursor: pointer;
    }
`;