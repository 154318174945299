import { TrashCan20 } from "@carbon/icons-react";
import React from "react";
import styled from "styled-components";

interface Props {
  onClick: () => void;
}

export function DeleteTableButton({onClick} : Props): JSX.Element {
  return (
    <Container onClick={onClick}>
      <TrashCan20 color="#ffff" />
    </Container>
  );
}

const Container = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
