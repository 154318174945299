import { Misc } from "type";
import React, { useEffect, useState } from "react";

import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { AddMiscPartModal } from "../add/addMiscModal";
import { ProductBase } from "modules/product/components/forms/SearchField";

interface Props {
    layerName: string;
    data: Misc[];
    docId: string;
    isVisible: boolean;
    callback: () => void;
    onClose: () => void;
}

export function AddMisc({
    layerName: Name,
    data,
    docId,
    callback,
    isVisible,
    onClose
  }: Props): JSX.Element {
    const firebase = useFirebaseContext();
    const [miscs, setMiscs] = useState<ProductBase[]>([]);

   useEffect(() => {
    firebase?.getMisc().then((res) => {
        const miscList: ProductBase[] = res.map(r => ({Name: r.name, Price: r.price}));
        setMiscs(miscList);
    });
  }, []);


    return (
        <AddMiscPartModal
          callback={callback}
          docId={docId}
          data={data}
          layerName={Name}
          productType='Misc'
          onClose={onClose}
          miscList={miscs}
          isVisible={isVisible} />
      );
}