import { MaterialModel } from 'services/domain/products/models/ProductModel';
import { JewelryProductModel } from 'services/domain/products/models/specifc/JewelryProductModel';
import { Material } from 'type';
import { ProductFirestore } from '../ProductFirestore';

export const MapProductToDomainModel = (product: ProductFirestore): JewelryProductModel => {
  return {
    name: product.name,
    averageCost: product.averageCost,
    category: product.category,
    id: product.id,
    sku: product.sku,
    labor: product.labor,
    marketPrice: product.marketPrice,
    imagePath: product.imagePath,
    gender: product.gender,
    season: product.season,
    developmentStatus: product.developmentStatus,
    specification: product.specification,
    costType: product.costType,
    vendors: !!product.productVendors
      ? Object.keys(product.productVendors)
          .map((key) => {
            const vendor = product.productVendors[key];
            return {
              id: key,
              name: vendor.name,
              country: vendor.country,
              isPreferred: vendor.isPreferred,
              itemWeight: vendor.itemWeight,
              moq: vendor.moq,
              vendorPrice: vendor.vendorPrice,
              status: vendor.status,
              note: vendor.note,
              transferPrice: vendor.targetPrice,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : undefined,
    misc: Object.keys(product.misc).map((key) => {
      const misc = product.misc[key];
      return {
        id: key,
        name: misc.name,
        createdAt: misc.createdAt,
        materials: misc.materials.map((m) => ({
          name: m.name,
          quantity: m.quantity,
          totalPrice: m.totalPrice,
          unitPrice: m.unitPrice,
          id: m.id,
        })),
      };
    }),
    productParts: Object.keys(product.productParts).map((key) => {
      const productParts = product.productParts[key];
      return {
        id: key,
        name: productParts.name,
        createdAt: productParts.createdAt,
        materials: productParts.materials.map((m) => ({
          name: m.Name,
          weight: m.Weight,
          basePrice: m.BasePrice,
          totalPrice: m.TotalPrice,
          type: m.Type,
          volume: m.Volume,
          category: m.Category,
          layerName: m.LayerName,
          id: m.ID,
          area: m.Area,
          density: m.Density,
        })),
      };
    }),
  };
};

export const MapMaterialModelToFirestoreModel = (product: MaterialModel): Material => {
  return {
    Name: product.name,
    Weight: product.weight,
    BasePrice: product.basePrice,
    TotalPrice: product.totalPrice,
    Type: product.type,
    Volume: product.volume,
    Category: product.category,
    LayerName: product.layerName,
    ID: product.id,
    Area: product.area || '',
    Density: product.density || '',
  };
};

// export const MapProductModelToFirestoreModel = (product: ProductModel) : ProductFirestore =>  {
//   let test : {[id: string] : MiscCollection} ;
//   test[''] = null;
//   return ({
//     name: product.name,
//     calculationMode: product.calculationMode,
//     category: product.category,
//     id: product.id,
//     sku: product.sku,
//     labor: product.labor,
//     marketPrice: product.marketPrice,
//     misc: product.misc.map(misc => {
//     //  const misc = product.misc[key];
//       return ( {
//         id: misc.id,
//         name: misc.name,
//         createdAt: misc.createdAt,
//         materials: misc.materials.map(m => ({
//           name: m.name,
//           quantity: m.quantity,
//           totalPrice: m.totalPrice,
//           unitPrice: m.unitPrice,
//           id: m.id
//         }))
//       })
//     }),
//     productParts: Object.keys(product.productParts).map(key => {
//       const productParts = product.productParts[key];
//       return ({
//         id: key,
//         name: productParts.name,
//         createdAt: productParts.createdAt,
//         materials: productParts.materials.map(m => ({
//           name: m.Name,
//           weight: m.Weight,
//           basePrice: m.BasePrice,
//           totalPrice: m.TotalPrice,
//           type: m.Type,
//           volume: m.Volume,
//           category: m.Category,
//           layerName: m.LayerName,
//           id: m.ID,
//           area: m.Area,
//           density: m.Density,
//         }))
//       })
//     }),
//   })
// }
