import { BrowserRouter, Switch } from 'react-router-dom';

import { NotAuthRoute } from './components/auth/NotAuthRoute';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { ProductPage } from 'components/pages/ProductPage';
import React from 'react';
import { UserProvider } from 'core/contexts/UserContext';
import { CategoryPage } from 'components/pages/CategoryPage';
import { LoginPage } from 'components/pages/LoginPage';
import CatalogPage from 'components/pages/CatalogPage';
import App from 'components/pages/HomePage';
import { VendorPage } from 'components/pages/VendorPage';
import { VendorListPage } from 'components/pages/VendorListPage';

export enum Routes {
  HOME = '/',
  CATALOG = '/catalog',
  PRODUCT = '/product/:id',
  LOGIN = '/login',
  CATEGORY = '/category/:id',
  VENDORLIST = '/vendors',
  VENDOR = '/vendors/:id',
  TEST = '/test/:id',
}

export default function AppRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <UserProvider>
          <PrivateRoute exact path={Routes.HOME} component={App} />
          <PrivateRoute exact path={Routes.CATALOG} component={CatalogPage} />
          <PrivateRoute exact path={Routes.PRODUCT} component={ProductPage} />
          <PrivateRoute exact path={Routes.CATEGORY} component={CategoryPage} />
          <PrivateRoute exact path={Routes.VENDORLIST} component={VendorListPage} />
          <PrivateRoute exact path={Routes.VENDOR} component={VendorPage} />
          <PrivateRoute exact path={Routes.TEST} component={VendorPage} />
          <NotAuthRoute path={Routes.LOGIN} component={LoginPage} />
        </UserProvider>
      </Switch>
    </BrowserRouter>
  );
}
