import { Material, MaterialType } from "type";
import React, { useEffect, useState } from "react";

import { ProductBase } from "../../../../forms/SearchField";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { AddProductPartModal } from "./addProductPartModal";
import { MaterialModel } from "services/domain/products/models/ProductModel";

interface Props {
  layerName: string;
  data: MaterialModel[];
  docId: string;
  isVisible: boolean;
  callback: () => void;
  onClose: () => void;
}

export function AddRawMaterial({
  layerName: Name,
  data,
  docId,
  callback,
  isVisible,
  onClose
}: Props): JSX.Element {
  const firebase = useFirebaseContext();
  const [productList, setProductList] = useState<ProductBase[]>([]);

  useEffect(() => {
    firebase?.getAPIMaterials().then((res) => {
      const product: ProductBase[] = res.map(r => ({Name: r.Name, Price: r.Price}));
      setProductList(product);
    });
  }, []);

  return (
    <AddProductPartModal
      callback={callback}
      docId={docId}
      data={data}
      layerName={Name}
      productType={MaterialType.BASE_MATERIAL}
      onClose={onClose}
      productList={productList}
      isVisible={isVisible} />
  );
}
