import { Container } from 'components/elements/Container';
import { Input } from 'components/elements/Input';
import { Label } from 'components/elements/Label';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';

interface InputContainerProps extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  error?: string;
  label: string;
  placeholder: string;
  type: string;
  value?: string;
  name: string;
  disabled?: boolean;
  black?: boolean;
  highlightValue?: boolean;
  onInputChange?: (val: string) => void;
  pattern?: string;
}

export const SimpleInputContainer: React.FC<InputContainerProps> = ({
  register,
  label,
  placeholder,
  type,
  value,
  name,
  disabled,
  black,
  highlightValue,
  pattern,
  onInputChange,
}) => {
  const onChange = (val: string) => {
    if (!!onInputChange && val != value) {
      onInputChange(val);
      return;
    }
  };

  return (
    <Container>
      <Label black={black}>{label}</Label>
      <Input
        pattern={pattern}
        onChange={(evt) => evt.target.validity.valid && onChange(evt.target.value)}
        value={value}
        ref={register}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        black={black}
        highlightValue={highlightValue}
      />
    </Container>
  );
};
