import React, { useState } from "react";

import { Add20 } from "@carbon/icons-react";
import { ModalWrapper } from "../../../components/common/modals/ModalWrapper";
import { TextInput } from "../../../components/common/modals/TextInput";
import styled from "styled-components";
import { useFirebaseContext } from "core/contexts/FirebaseContext";

interface Props {
  title: string;
}

export default function AddCategoryModal({ title }: Props): JSX.Element {
  const [data, setData] = useState<string>("");

  const firebase = useFirebaseContext();

  const handleSubmit = () => {
    firebase.createCategory(data);
  };

  const handleChange = (param: string) => {
    setData(param);
  };

  return (
    <>
      <ModalWrapper
        button={
          <ContainerHeaderTitle>
            <Add20 />
            <p>{title}</p>
          </ContainerHeaderTitle>
        }
        handleSubmit={handleSubmit}
        title="Add Category"
      >
        <TextInput 
          label="Name" 
          handleChange={handleChange} 
          defaultValue={data} 
        />
      </ModalWrapper>
    </>
  );
}

const ContainerHeaderTitle = styled.div`
  width: 140px;
  height: 40px;
  border: 1px solid #353535;
  text-align: center;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  margin-right: 20px;

  p {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
`;
