import * as React from "react";
import classNames from "classnames";

import { StyledLoader, StyledLoadingMessage, StyledWrapper } from "./LoadingElement.styles";
import { Loading } from "carbon-components-react";


type Props = {
    loadingMessage?: string;
    size?: number;
    pageCentered?: boolean;
    className?: string;
};

export class LoadingElement extends React.PureComponent<Props> {
    static defaultProps = {
        size: 50,
        pageCentered: false,
    };

    private renderSpinner() {
        const { loadingMessage, className } = this.props;
        return (
            <>
                <StyledLoader className={classNames("loading-element", className)}>
                    <Loading withOverlay={false}/>
                </StyledLoader>
                {!!loadingMessage && <StyledLoadingMessage>{loadingMessage}</StyledLoadingMessage>}
            </>
        );
    }

    render() {
        return this.props.pageCentered ? <StyledWrapper>{this.renderSpinner()}</StyledWrapper> : this.renderSpinner();
    }
}
