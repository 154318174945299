import { chunkArray } from "core/utilities/array";
import { Routes } from "AppRouter";
import React from "react";
import { useHistory } from "react-router";
import { StyledCategoryItem, StyledCategorySelectorContainer } from "./CategorySelector.styles";
import { CategoryFirestore } from "services/external/firebase/models/CategoryFirestore";

interface Props {
  categories: CategoryFirestore[];
}

export const CategorySelector: React.FC<Props> = ({ categories }) => {
  const history = useHistory();

  return (
    <>
      {chunkArray(categories, 4).map((chunk, index) => (
        <StyledCategorySelectorContainer key={index}>
          {chunk.map(
            (value: CategoryFirestore, i: number, arr: CategoryFirestore[]) => (
              <StyledCategoryItem
                onClick={() =>
                  history.push(Routes.CATEGORY.replace(":id", value.id))
                }
                isEmpty={arr.length !== 4}
                key={i}
              >
                {value.name} - ({value.products.length})
              </StyledCategoryItem>
            )
          )}
        </StyledCategorySelectorContainer>
      ))}
    </>
  );
};
