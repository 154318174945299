import React from "react";

import Collapser from "components/common/Collapser";
import { ContainerSection } from "components/common/table/styled";
import { HeaderLayout } from "components/common/HeaderLayout";
import { Layout } from "components/common/Layout";
import styled from "styled-components";
import { useParams } from "react-router";
import { ProductBackButton } from "modules/product/components/ProductBackButton";
import { useGetVendorByIdSnapshot } from "core/hooks/firebase/vendors/useGetVendorById";
import { VendorInformationForms } from "modules/vendors/components/vendorInformations/VendorInformationsForms";
import { DeleteVendor } from "modules/vendors/components/DeleteVendor";

export function VendorPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { vendor } = useGetVendorByIdSnapshot(id);

  return (
    <Layout>
      {vendor && (
        <>
          <HeaderLayout
            title={<ProductBackButton name={vendor.name} />}
            rightComponent={<DeleteVendor />}
          />
          <Collapser title="Vendor information">
            <ProductInformationSection>
              <StyledProductInformationBlock>
                <VendorInformationForms
                  defaultValue={{
                    name: vendor.name,
                    country: vendor.country,
                  }}
                />
              </StyledProductInformationBlock>
            </ProductInformationSection>
          </Collapser>
          <Collapser title="Products Made">
            <ContainerSection></ContainerSection>
          </Collapser>
        </>
      )}
    </Layout>
  );
}

const StyledProductInformationBlock = styled.div`
  background: white;
  padding: 20px;
  margin: 20px;
  width: min-content;
  min-width: 300px;
  min-height: 200px;
  div {
    margin-bottom: 15px;
  }
`;

const ProductInformationSection = styled.div`
  background-color: #000000;
  padding: 1rem;
  width: 100%;
  display: flex;
`;

export const StyledCollapser = styled.div`
  CollapseContent {
    background-color: #1e1e1e;
  }
`;

export const SectionHeader = styled.div`
  width: 80px;
  width: 100%;
  background-color: #fff;
`;
