import { DropDown } from "components/common/Dropdown";
import React, { useEffect, useState } from "react";

import { TextInput } from "../../../../components/common/modals/TextInput";
import styled from "styled-components";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { VendorModel } from "services/external/firebase/models/VendorsModel";
import { ProductVendorsFirebase } from "services/external/firebase/models/ProductFirestore";
import { vendorStatus } from "core/data/vendorStatus";
import { Add32 } from "@carbon/icons-react";
import { ModalWrapper } from "components/common/modals/ModalWrapper";

interface Props {
  productId: string;
}

export function AddProductVendorsModal({productId} : Props): JSX.Element {
  const firebase = useFirebaseContext();
  const [vendorList, setVendorList] = useState<VendorModel[]>([]);
  const [vendor, setVendor] = useState<ProductVendorsFirebase>({
    name: "",
    country: "",
    vendorPrice: 0,
    targetPrice: 0,
    itemWeight: "",
    moq: "",
    status: "",
    note: "",
    isPreferred: false
  });

  useEffect(() => {
    const fetchVendors = async () => {
      const vendors = await firebase.VendorService.getAllVendors();
      setVendorList(vendors);
      if((vendors)) {
        const [firstVendor] = vendors;
        const [firstStatus] = vendorStatus;
        setVendor({...vendor, name: firstVendor.name, country: firstVendor.country, status: firstStatus})
      }
    
    } 
    fetchVendors();
  }, [])

  const handleSubmit = async () => {
    if(!!vendor && !!vendor.name) {
      await firebase.ProductService.addVendorToProduct(vendor, productId);
    }
  };

  const button = (
    <AddVendorElement><Add32/><span>Add vendor</span></AddVendorElement>
  );

  const onVendorChange = (vendorId: string) => {
    const {name, country} = vendorList.find(vendor => vendor.id === vendorId);
    setVendor({
      ...vendor,
      name,
      country
    })
  }

  return (
    <ModalWrapper
    handleSubmit={handleSubmit}
    button={button}
    title="Add Vendor" >
      <StyledModalContent>
        <DropDown
              value=""
              label="Vendor"
              name="vendor"
              onChange={onVendorChange}
              selectors={vendorList.map((vendor) => ({
                name: vendor.name,
                value: vendor.id
              }))}
              black={true}
          />
            <DropDown
              value=""
              label="Current Status"
              name="currentStatus"
              onChange={(e: string) => setVendor({ ...vendor, status: e })}
              selectors={vendorStatus.map((status) => ({
                name: status,
                value: status
              }))}
              black={true}
          />
          <TextInput
            label="Target price"
            handleChange={(e: string) => setVendor({ ...vendor, targetPrice: parseInt(e) })}
          />
          <TextInput
            label="Vendor price"
            handleChange={(e: string) => setVendor({ ...vendor, vendorPrice: parseInt(e) })}
            defaultValue={vendor.vendorPrice?.toString()}
          />
          <TextInput
            label="Item weight"
            handleChange={(e: string) => setVendor({ ...vendor, itemWeight: e })}
            defaultValue={vendor.itemWeight}
          />
        <TextInput
            label="MOQ"
            handleChange={(e: string) => setVendor({ ...vendor, moq: e })}
            defaultValue={vendor.moq}
          />
          <TextInput
            label="Note"
            handleChange={(e: string) => setVendor({ ...vendor, note: e })}
            defaultValue={vendor.note}
          />
      </StyledModalContent>
    </ModalWrapper>
  );
}


const AddVendorElement = styled.div`
  align-items: center;  
  background-color: ${(props) => props.theme.colors.white};
  border: dashed 3px;
  color: ${(props) => props.theme.colors.mainColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 285px;
  min-height: 300px;

  :hover {
    cursor:pointer;
  }

  > * {
    margin: 10px;
  }
`


const StyledModalContent = styled.div `
  > * {
    margin-bottom: 10px;
  }
  `

const ContainerHeaderTitle = styled.div`
  width: 140px;
  height: 40px;
  border: 1px solid unabacusblack;
  text-align: center;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #efefef;

  p {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
`;
