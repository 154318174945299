import { ILabor, MarketPriceDataModel, Material, MaterialFirestore, MaterialType, Misc, MiscCollection } from 'type';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { ProductFirestore, ProductVendorsFirebase } from './models/ProductFirestore';
import { uuidv4 } from 'core/utilities/uuid';
import { CalculationMethodType, LaborDifficultyType } from 'modules/product/components/labor/components/calculation/CalculationDefinition';
import { MaterialModel } from 'services/domain/products/models/ProductModel';
import { MapMaterialModelToFirestoreModel } from './models/mapper/ProductFirestoreMapper';

export const companyName = 'TeAskuaU9OMojQv6YELZ';

enum CollectionsFirestore {
  PRODUCTS = 'Products',
  MATERIALS = 'Materials',
  CATEGORIES = 'Categories',
  PRODUCT_PARTS = 'productParts',
  MISC = 'misc',
  LABOR = 'labor',
  PLATING = 'plating',
}

export class FirebaseProductService {
  private FIRESTORE: firebase.firestore.Firestore;
  private PRODUCTS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private MATERIALS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firebaseCtx: firebase.app.App) {
    this.FIRESTORE = firebaseCtx.firestore();
    this.initFirebaseCollection();
  }

  /**
   * Init collections variables
   */
  initFirebaseCollection(): void {
    this.PRODUCTS = this.FIRESTORE.collection('clients').doc(companyName).collection(CollectionsFirestore.PRODUCTS);
  }

  /**
   *
   * @param {string} id
   */
  async getLaborByProductId(id: string): Promise<ILabor[]> {
    const querySnapshot = await this.PRODUCTS.doc(id).collection(CollectionsFirestore.LABOR).get();
    const list: ILabor[] = [];
    querySnapshot.forEach(function (doc) {
      const id = doc.id;
      const data = doc.data() as ILabor;
      data.id = id;
      list.push(data);
    });
    return list;
  }

  /**
   *
   * @param {string} id
   */
  async getProductPartsByProductId(id: string): Promise<MaterialFirestore[]> {
    const fetchedProductParts = await this.PRODUCTS.doc(id).get();

    const list: MaterialFirestore[] = [];

    const data = fetchedProductParts.data();
    if (!!data.productParts) {
      Object.keys(data.productParts).map((key) => {
        const product = data.productParts[key];
        list.push({
          name: product.name,
          id: key,
          materials: product.materials,
          createdAt: product.createdAt,
        });
      });
    }

    return list.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  /**
   *
   * @param {string} id
   */
  async getMiscByProductId(id: string): Promise<MiscCollection[]> {
    const fetchedMisc = await this.PRODUCTS.doc(id).get();

    const list: MiscCollection[] = [];

    const data = fetchedMisc.data();
    if (!!data.misc) {
      Object.keys(data.misc).map((key) => {
        const misc = data.misc[key];
        list.push({
          name: misc.name,
          id: key,
          materials: misc.materials,
          createdAt: misc.createdAt,
        });
      });
    }

    return list.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  /**
   *
   * @param {ProductFirestore} product
   */
  createProduct(product: ProductFirestore): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
    return this.PRODUCTS.add({
      ...product,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  /**
   *
   * @param {string} id
   * @returns Promise<void>
   */
  deleteProduct(id: string): Promise<void> {
    return this.PRODUCTS.doc(id).delete();
  }

  /**
   *
   * @param {string} id
   * @param {ProductFirestore} product
   */
  updateProduct(id: string, product: ProductFirestore): Promise<void> {
    const promise = this.PRODUCTS.doc(id).update({ ...product });
    this.updateProductDate(id);
    return promise;
  }

  /**
   *
   * @param {MaterialFirestore} material
   * @param {string} id
   */
  addProductPartToProduct(material: MaterialFirestore, id: string): void {
    const productPartId = uuidv4();
    this.PRODUCTS.doc(id).set(
      {
        [`productParts`]: {
          [productPartId]: {
            name: material.name,
            materials: material.materials,
            createdAt: new Date(),
          },
        },
      },
      { merge: true }
    );
    this.updateProductDate(id);
  }

  addMiscPartToProduct(id: string, misc: MiscCollection): void {
    const miscPartId = uuidv4();
    this.PRODUCTS.doc(id).set(
      {
        [`misc`]: {
          [miscPartId]: {
            name: misc.name,
            materials: misc.materials,
            createdAt: new Date(),
          },
        },
      },
      { merge: true }
    );
    this.updateProductDate(id);
  }

  addVendorToProduct(productVendor: ProductVendorsFirebase, productId: string): void {
    const productVendorId = uuidv4();
    this.PRODUCTS.doc(productId).set(
      {
        [`productVendors`]: {
          [productVendorId]: {
            ...productVendor,
            createdAt: new Date(),
          },
        },
      },
      { merge: true }
    );
    this.updateProductDate(productId);
  }

  /**
   *
   * @param {string} id
   * @param {string} docId
   * @param {Material[]} materials
   */
  updateProductVendor(productVendor: ProductVendorsFirebase, productId: string, productVendorId: string): void {
    if (!!productVendor && !!productId) {
      this.PRODUCTS.doc(productId).update({ [`productVendors.${productVendorId}`]: productVendor });
      this.updateProductDate(productId);
    }
  }

  /**
   *
   * @param {string} id
   * @param {ILabor} labor
   */
  createProductLabor(id: string, labor: ILabor): void {
    this.PRODUCTS.doc(id).collection(CollectionsFirestore.LABOR).add(labor);
    this.updateProductDate(id);
  }

  /**
   *
   * @param {string} id
   * @param {string} docId
   * @param {Material[]} materials
   */
  updateMaterials(id: string, docId: string, materials: MaterialModel[]): void {
    if (!!id && !!docId && !!materials) {
      const firebaseMaterialModel = materials.map(MapMaterialModelToFirestoreModel);
      const materialToUpdate = firebaseMaterialModel.map(this.calculateTotal);
      this.PRODUCTS.doc(id).update({ [`productParts.${docId}.materials`]: [...materialToUpdate] });
      this.updateProductDate(id);
    }
  }

  updateLabor(id: string, laborType: LaborDifficultyType, laborCalculationMethod: CalculationMethodType, laborCost: number): void {
    console.log(`salut ${laborCost} ${laborType} ${laborCalculationMethod}`);
    this.PRODUCTS.doc(id).update({
      [`labor`]: {
        type: laborType,
        cost: laborCost,
        calculationMethod: laborCalculationMethod,
      },
    });
    this.updateProductDate(id);
  }

  async removedPreferedVendor(productId: string): Promise<void> {
    if (!!productId) {
      const product = await this.getProductById(productId);
      const vendors = product.productVendors;
      if (!!vendors) {
        Object.keys(vendors).map((key) => {
          vendors[key].isPreferred = false;
        });
      }
      this.PRODUCTS.doc(productId).update({ [`productVendors`]: vendors });
      this.updateProductDate(productId);
    }
  }

  async updatePreferedVendor(productId: string, preferedVendorId: string): Promise<void> {
    if (!!productId && !!preferedVendorId) {
      const product = await this.getProductById(productId);
      const vendors = product.productVendors;
      if (!!vendors) {
        Object.keys(vendors).map((key) => {
          vendors[key].isPreferred = key == preferedVendorId;
        });
      }
      this.PRODUCTS.doc(productId).update({ [`productVendors`]: vendors });
      this.updateProductDate(productId);
    }
  }

  deleteLabor(productId: string, laborId: string): void {
    this.PRODUCTS.doc(productId).collection(CollectionsFirestore.LABOR).doc(laborId).delete();
    this.updateProductDate(productId);
  }

  updateMiscs(id: string, docId: string, miscs: Misc[]): void {
    if (!!id && !!docId && !!miscs) {
      const miscToUpdate = miscs.map(this.calculateMiscTotal);
      this.PRODUCTS.doc(id).update({ [`misc.${docId}.materials`]: miscToUpdate });
      this.updateProductDate(id);
    } else {
      // log
    }
  }

  updateMarketPrice(id: string, marketPrice: MarketPriceDataModel): void {
    console.log('update market price');

    this.PRODUCTS.doc(id).update({ [`marketPrice`]: marketPrice });
    this.updateProductDate(id);
  }

  /**
   *
   * @param {string} productId
   */
  async getMarketPriceByProductId(productId: string): Promise<MarketPriceDataModel> {
    const fetchedMisc = await this.PRODUCTS.doc(productId).get();

    const data = fetchedMisc.data();
    if (!!data.marketPrice) {
      return data.marketPrice as MarketPriceDataModel;
    }
  }

  private calculateMiscTotal(misc: Misc): Misc {
    return {
      ...misc,
      totalPrice: parseFloat(misc.quantity) * misc.unitPrice,
    };
  }

  /**
   *
   * @param {string} id
   */
  async getProductById(id: string): Promise<ProductFirestore> {
    const fetchedProduct = await this.PRODUCTS.doc(id).get();
    return fetchedProduct.data() as ProductFirestore;
  }

  addMiscToCollectionProduct(id: string, docId: string, misc: MiscCollection): void {
    this.PRODUCTS.doc(id).collection('misc').doc(docId).set(misc);
  }

  updateMiscToCollectionProduct(id: string, docId: string, miscs: Misc[]): void {
    this.PRODUCTS.doc(id).collection('misc').doc(docId).update({ miscs });
  }

  updateRawMaterialName(id: string, docId: string, name: string): void {
    this.PRODUCTS.doc(id).update({ [`productParts.${docId}.name`]: name });
    this.updateProductDate(id);
  }

  updateImagePath(id: string, imagePath: string): void {
    this.PRODUCTS.doc(id).update({ [`imagePath`]: imagePath });
    this.updateProductDate(id);
  }

  updateSpecificationImagePath(id: string, imagePath: string): void {
    this.PRODUCTS.doc(id).update({ [`specification.imagePath`]: imagePath });
    this.updateProductDate(id);
  }

  updateMiscName(id: string, docId: string, name: string): void {
    this.PRODUCTS.doc(id).update({ [`misc.${docId}.name`]: name });
    this.updateProductDate(id);
  }

  deleteProductPart(id: string, docId: string): void {
    this.PRODUCTS.doc(id).set({ productParts: { [docId]: firebase.firestore.FieldValue.delete() } }, { merge: true });
    this.updateProductDate(id);
  }

  deleteMisc(id: string, docId: string): void {
    this.PRODUCTS.doc(id).set({ misc: { [docId]: firebase.firestore.FieldValue.delete() } }, { merge: true });
    this.updateProductDate(id);
  }

  updateProductDate(id: string): void {
    this.PRODUCTS.doc(id).update({ updatedAt: new Date() });
  }

  private calculateTotal(material: Material): Material {
    return {
      ...material,
      TotalPrice:
        material.Type === MaterialType.BASE_MATERIAL
          ? (parseFloat(material.Weight) * parseFloat(material.BasePrice)).toFixed(2)
          : (parseFloat(material.Volume) * parseFloat(material.BasePrice)).toFixed(2),
    };
  }
}
