import React from "react";
import { useHistory } from "react-router";

import { ArrowLeft32 } from "@carbon/icons-react";
import styled from "styled-components";

interface Prop {
  count: number;
  categoryName: string;
}

export default function DropDownCategory({count, categoryName}: Prop): JSX.Element {

  const history = useHistory();


  return (
    <Container>
      <Icon onClick={() => history.goBack()} />
      <ProductCount>
            <ProductCountText>{count}</ProductCountText>
          </ProductCount>
      {categoryName}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(ArrowLeft32)`
  &:hover {
    cursor: pointer;
  }
`;

const ProductCount = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
`;

const ProductCountText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;
