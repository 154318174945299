import { MaterialAPI, MaterialCreation, MaterialFirestore, MaterialType, Plating, StaticMisc } from 'type';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { CategoryFirestore } from './models/CategoryFirestore';
import { ProductFirestore } from './models/ProductFirestore';
import { DifficultyMethodDefinition } from 'modules/product/components/labor/components/calculation/CalculationDefinition';
import { FirebaseProductService } from './FirebaseProductService';
import { SearchProductParameters, SearchProductsResponse } from './parameters/SearchProductParameters';
import { CostType, ProductModel } from 'services/domain/products/models/ProductModel';
import { FirebaseVendorService } from './FirebaseVendorsService';

export const companyName = 'TeAskuaU9OMojQv6YELZ';

enum CollectionsFirestore {
  PRODUCTS = 'Products',
  MATERIALS = 'Materials',
  CATEGORIES = 'Categories',
  PRODUCT_PARTS = 'productParts',
  MISC = 'misc',
  LABOR = 'labor',
  PLATING = 'plating',
}

export class FirebaseUtility {
  private FIRESTORE: firebase.firestore.Firestore;
  private PRODUCTS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private MATERIALS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private CATEGORIES!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private GLOBAL_MATERIALS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private MISC!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private PLATING!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  public ProductService: FirebaseProductService;
  public VendorService: FirebaseVendorService;

  constructor(firebaseCtx: firebase.app.App) {
    this.FIRESTORE = firebaseCtx.firestore();
    this.initFirebaseCollection();
    this.ProductService = new FirebaseProductService(firebaseCtx);
    this.VendorService = new FirebaseVendorService(firebaseCtx);
  }

  /**
   * Init collections variables
   */
  initFirebaseCollection(): void {
    this.PRODUCTS = this.FIRESTORE.collection('clients').doc(companyName).collection(CollectionsFirestore.PRODUCTS);
    this.MATERIALS = this.FIRESTORE.collection('clients').doc(companyName).collection(CollectionsFirestore.MATERIALS);
    this.CATEGORIES = this.FIRESTORE.collection('clients').doc(companyName).collection(CollectionsFirestore.CATEGORIES);
    this.GLOBAL_MATERIALS = this.FIRESTORE.collection('materials');
    this.MISC = this.FIRESTORE.collection('misc');
    this.PLATING = this.FIRESTORE.collection(CollectionsFirestore.PLATING);
  }

  /**
   *
   * @param {MaterialCreation} productMaterial
   */
  createProductMaterial(productMaterial: MaterialCreation): void {
    this.MATERIALS.add({
      ...productMaterial,
      createdAt: new Date(),
    });
  }

  /**
   *
   * @param {string} name
   */
  createCategory(name: string): void {
    this.CATEGORIES.add({ name, createdAt: new Date(), updatedAt: new Date() });
  }

  calculateTotalPrice2(product: ProductModel) {
    switch (product.costType) {
      case CostType.AverageCost: {
        return {
          totalPrice: product.averageCost,
          totalBaseMaterialPrice: 0,
          totalPlatingPrice: 0,
          totalMiscPrice: 0,
          totalLaborPrice: 0,
        };
      }
      case CostType.PreferedVendorCost: {
        const preferredVendor = product.vendors.find((v) => v.isPreferred);
        return {
          totalPrice: !!preferredVendor?.vendorPrice ? preferredVendor?.vendorPrice : 0,
          totalBaseMaterialPrice: 0,
          totalPlatingPrice: 0,
          totalMiscPrice: 0,
          totalLaborPrice: 0,
        };
      }
      default: {
        let totalMiscPrice = 0;
        let totalLaborPrice = 0;
        let totalBaseMaterialPrice = 0;
        let totalPlatingPrice = 0;
        const { productParts, misc, labor } = product;

        if (!!productParts) {
          productParts.forEach((element) => {
            //  const data = element as MaterialFirestore;
            const data = element;
            const baseMaterialPrice = data.materials
              .filter((material) => material.type === MaterialType.BASE_MATERIAL)
              .map((material) => material.totalPrice)
              .reduce((acc, currentPrice) => (acc += parseFloat(currentPrice)), 0);
            const platingPrice = data.materials
              .filter((material) => material.type === MaterialType.PLATING)
              .map((material) => material.totalPrice)
              .reduce((acc, currentPrice) => (acc += parseFloat(currentPrice)), 0);
            totalBaseMaterialPrice += baseMaterialPrice;
            totalPlatingPrice += platingPrice;
          });
        }
        if (!!misc) {
          misc.forEach((element) => {
            const data = element;
            data.materials.forEach((value) => {
              if (!isNaN(value.totalPrice)) {
                totalMiscPrice += value.totalPrice;
              }
            });
          });
        }

        if (!!labor) {
          const difficultyMap = DifficultyMethodDefinition.find(({ type }) => type == labor.type);
          productParts.map((part) => {
            totalLaborPrice += part.materials.reduce((acc, current) => {
              const difficultyValue = difficultyMap.weightPercentageRules.find(
                (rule) => parseFloat(current.weight) <= rule.maxWeightGram && parseFloat(current.weight) >= rule.minWeightGram
              );
              const estimatedLabor = !!difficultyValue ? (parseFloat(current.totalPrice) * difficultyValue.percentage) / 100 : 0;
              return acc + estimatedLabor;
            }, 0);
          });
        }
        return {
          totalPrice: totalBaseMaterialPrice + totalPlatingPrice + totalLaborPrice + totalMiscPrice,
          totalBaseMaterialPrice,
          totalPlatingPrice,
          totalMiscPrice,
          totalLaborPrice,
        };
      }
    }
  }

  calculateTotalPrice(product: ProductFirestore) {
    switch (product.costType) {
      case CostType.AverageCost: {
        return {
          totalPrice: product.averageCost,
          totalBaseMaterialPrice: 0,
          totalPlatingPrice: 0,
          totalMiscPrice: 0,
          totalLaborPrice: 0,
        };
      }
      case CostType.PreferedVendorCost: {
        const preferredVendor = !!product?.productVendors ? Object.values(product.productVendors).find((v) => v.isPreferred) : null;
        return {
          totalPrice: !!preferredVendor ? preferredVendor.vendorPrice : 0,
          totalBaseMaterialPrice: 0,
          totalPlatingPrice: 0,
          totalMiscPrice: 0,
          totalLaborPrice: 0,
        };
      }
      default: {
        let totalMiscPrice = 0;
        let totalLaborPrice = 0;
        let totalBaseMaterialPrice = 0;
        let totalPlatingPrice = 0;
        const { productParts, misc, labor } = product;

        if (!!productParts) {
          Object.values(productParts).forEach((element) => {
            const data = element as MaterialFirestore;
            const baseMaterialPrice = data.materials
              .filter((material) => material.Type === MaterialType.BASE_MATERIAL)
              .map((material) => material.TotalPrice)
              .reduce((acc, currentPrice) => (acc += parseFloat(currentPrice)), 0);
            const platingPrice = data.materials
              .filter((material) => material.Type === MaterialType.PLATING)
              .map((material) => material.TotalPrice)
              .reduce((acc, currentPrice) => (acc += parseFloat(currentPrice)), 0);
            totalBaseMaterialPrice += baseMaterialPrice;
            totalPlatingPrice += platingPrice;
          });
        }
        if (!!misc) {
          Object.values(misc).forEach((element) => {
            const data = element as any;
            data.materials.forEach((value) => {
              if (!isNaN(value.totalPrice)) {
                totalMiscPrice += value.totalPrice;
              }
            });
          });
        }

        if (!!labor) {
          if (labor.calculationMethod === 'percentage') {
            const difficultyMap = DifficultyMethodDefinition.find(({ type }) => type == labor.type);
            Object.values(productParts).map((part) => {
              totalLaborPrice += part.materials.reduce((acc, current) => {
                const difficultyValue = difficultyMap.weightPercentageRules.find(
                  (rule) => parseFloat(current.Weight) <= rule.maxWeightGram && parseFloat(current.Weight) >= rule.minWeightGram
                );
                const estimatedLabor = !!difficultyValue ? (parseFloat(current.TotalPrice) * difficultyValue.percentage) / 100 : 0;
                return acc + estimatedLabor;
              }, 0);
            });
          } else if (labor.calculationMethod === 'custom') {
            totalLaborPrice = labor.cost;
          }
        }
        return {
          totalPrice: totalBaseMaterialPrice + totalPlatingPrice + totalLaborPrice + totalMiscPrice,
          totalBaseMaterialPrice,
          totalPlatingPrice,
          totalMiscPrice,
          totalLaborPrice,
        };
      }
    }
  }

  async getTotalPriceHook(id: string, callback: (any) => void) {
    const unsub = this.PRODUCTS.doc(id).onSnapshot((d) => {
      const product = d.data() as ProductFirestore;
      if (!!product) {
        const price = this.calculateTotalPrice(product);
        callback(price);
      }
    });
    return () => unsub();
  }

  async getTotalPrice(id: string) {
    const querySnapshot = await this.PRODUCTS.doc(id).get();
    const product = querySnapshot.data() as ProductFirestore;

    return this.calculateTotalPrice(product);
  }

  /**
   * @return Promise<CategoryFirestore[]>
   */
  async getCategories(): Promise<CategoryFirestore[]> {
    const categories: CategoryFirestore[] = [];
    await this.CATEGORIES.get().then(async (results) => {
      results.forEach((doc) => {
        categories.push(({
          ...doc.data(),
          id: doc.id,
          products: [],
        } as unknown) as CategoryFirestore);
      });
      await this.PRODUCTS.get().then((docSnaps) => {
        docSnaps.forEach((doc) => {
          const product = doc.data() as ProductFirestore;
          const categoryForProduct = categories.find((c) => c.id === product.category);
          if (!!categoryForProduct) {
            categoryForProduct?.products.push(product);
          }
        });
      });
    });

    return categories;
  }

  /**
   *
   */
  async getAPIMaterials(): Promise<MaterialAPI[]> {
    const querySnapshot = await this.GLOBAL_MATERIALS.get();
    const list: MaterialAPI[] = [];
    querySnapshot.forEach(function (doc) {
      list.push(doc.data() as MaterialAPI);
    });
    return list;
  }

  /**
   *
   * @param {string} id
   */
  async getCurrentCategoryName(id: string): Promise<string> {
    const querySnapshot = await this.CATEGORIES.doc(id).get();
    const data = querySnapshot.data() as CategoryFirestore;
    return data.name;
  }

  /**
   *
   * @param {string} id
   */
  async getProductByCategory(
    id: string,
    last: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    limit = 5
  ): Promise<{
    list: ProductFirestore[];
    lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    isPaginationDone: boolean;
  }> {
    const fetchedProductFirestore = await this.PRODUCTS.where('category', '==', id)
      .orderBy('createdAt')
      .startAfter(!!last ? last : 0)
      .limit(limit)
      .get();
    const list: ProductFirestore[] = [];
    let lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    fetchedProductFirestore.docs.map((doc) => {
      const productFirestore = doc.data() as ProductFirestore;
      const { totalPrice } = this.calculateTotalPrice(productFirestore);
      list.push({
        ...productFirestore,
        id: doc.id,
        cost: totalPrice.toFixed(2),
      });
      lastDoc = doc;
    });
    const isPaginationDone = !(list.length == limit);

    return { list, lastDoc, isPaginationDone };
  }

  /**
   *
   * @param {string} id
   */
  async searchProduct(searchParameters: SearchProductParameters): Promise<SearchProductsResponse> {
    const { categoryId, limit, lastSearchedDoc } = searchParameters;
    let fetchedProductFirestore: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
    if (!!categoryId) {
      fetchedProductFirestore = await this.PRODUCTS.where('category', '==', categoryId)
        .orderBy('createdAt')
        .startAfter(!!lastSearchedDoc ? lastSearchedDoc : 0)
        .limit(limit)
        .get();
    } else {
      fetchedProductFirestore = await this.PRODUCTS.orderBy('createdAt')
        .startAfter(!!lastSearchedDoc ? lastSearchedDoc : 0)
        .limit(limit)
        .get();
    }

    const products: ProductFirestore[] = [];
    let lastFetchedDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    fetchedProductFirestore.docs.map((doc) => {
      const productFirestore = doc.data() as ProductFirestore;
      const { totalPrice } = this.calculateTotalPrice(productFirestore);
      products.push({
        ...productFirestore,
        id: doc.id,
        cost: totalPrice?.toFixed(2) || 'error',
      });
      lastFetchedDoc = doc;
    });
    const isPaginationDone = !(products.length == limit);

    return { products, lastFetchedDoc, isPaginationDone };
  }

  async getMisc(): Promise<StaticMisc[]> {
    const fetchedMiscs = await this.MISC.orderBy('name', 'asc').get();
    const list: StaticMisc[] = [];
    fetchedMiscs.forEach((element) => list.push({ ...(element.data() as StaticMisc), id: element.id }));
    return list;
  }

  async getPlating(): Promise<Plating[]> {
    const list: Plating[] = [];
    const platings = await this.PLATING.get();
    platings.forEach((element) => {
      list.push(element.data() as Plating);
    });
    return list;
  }
}
