import { ArrowLeft32 } from "@carbon/icons-react";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

interface Props {
  name: string;
}

export function ProductBackButton({ name }: Props): JSX.Element {
  const history = useHistory();

  return (
    <ContainerHeaderTitle>
      <Icon onClick={() => history.goBack()} />
      <p>{name}</p>
    </ContainerHeaderTitle>
  );
}

const ContainerHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 90px;

  p {
    height: auto;
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
  }
`;

const Icon = styled(ArrowLeft32)`
  &:hover {
    cursor: pointer;
  }
`;
