import React, { Dispatch, SetStateAction, useState } from "react";

import { Material, MaterialFirestore, MaterialType } from "type";
import ModalSquare, { PartToImport } from "./ModalSquare";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { useParams } from "react-router";
import { uuidv4 } from "core/utilities/uuid";
import { Modal } from "components/common/modals/Modal";

interface Props {
  data: Material[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export function CSVModal({ data, setOpen, isOpen }: Props): JSX.Element {
  const [partsToImport, setPartsToImport] = useState<MaterialFirestore[]>([]);
  const firebase = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const handleSubmit = () => {
    partsToImport.forEach(partToImport => {
      
      firebase.ProductService.addProductPartToProduct(partToImport, id)
      onClose();
    })
  };

  const onClose = () => {
    setOpen(false);
  }


  const calculateWeight = (volume: number, density: number): number => {
    return volume * density;
  }


  // rework needed to simplify logic
  const onUpdate = (partToImport: PartToImport) => {
     if(!partToImport.isSelected) {
      const up = partsToImport.filter(p=>p.name !== partToImport.layerName);
      setPartsToImport(up);
      return;
     }

     const existingPartToImport = partsToImport.find(part => part.name === partToImport.layerName) || ({name: partToImport.layerName, id: uuidv4(), materials: []}) as MaterialFirestore;
       if(!!partToImport.baseMaterial) {
         const {baseMaterial} = partToImport;
         const weight = calculateWeight(parseFloat(partToImport.volume), parseFloat(baseMaterial.Density || '1'));
         const totalPrice = weight * parseFloat(baseMaterial.Price);
         const baseMaterialToAdd: Material = {
           BasePrice: baseMaterial.Price,
           Category: '',
           ID: uuidv4(),
           LayerName: partToImport.layerName,
           Name: partToImport.baseMaterial.Name,
           TotalPrice: totalPrice.toFixed(2),
           Type: MaterialType.BASE_MATERIAL,
           Volume: '',
           Weight: calculateWeight(parseFloat(partToImport.volume), parseFloat(baseMaterial.Density || '1')).toFixed(2),
           Area: '',
           Density: ''
         }

         const existingBaseMaterial = existingPartToImport?.materials.find(m => m.Type === MaterialType.BASE_MATERIAL);
         if(existingBaseMaterial) {
          existingPartToImport.materials = existingPartToImport.materials.filter(m => m.Type !== MaterialType.BASE_MATERIAL);
          }
          existingPartToImport?.materials.push(baseMaterialToAdd)
       }
       if(!!partToImport.platingMaterial) {
        const {platingMaterial} = partToImport;
        const platingMaterialToAdd: Material = {
          BasePrice: platingMaterial.price,
          Category: '',
          ID: uuidv4(),
          LayerName: partToImport.layerName,
          Name: platingMaterial.name,
          TotalPrice: (parseFloat(platingMaterial.price) * parseFloat(partToImport.surface)).toFixed(6),
          Type: MaterialType.PLATING,
          Volume: partToImport.surface,
          Weight: '',
          Area: partToImport.surface,
          Density: ''
        }
        const existingPlating = existingPartToImport?.materials.find(m => m.Type === MaterialType.PLATING);
         if(existingPlating) {
          existingPartToImport.materials = existingPartToImport.materials.filter(m => m.Type !== MaterialType.PLATING);
          }
          existingPartToImport?.materials.push(platingMaterialToAdd)

     }
     if(!partsToImport.find(p => p.name === existingPartToImport.name)) {
      partsToImport.push(existingPartToImport);
    }    
    const up = partsToImport.map(p => {
      if(p.name === existingPartToImport.name) {
        p = existingPartToImport;
      }
        return p;
    })
    setPartsToImport(up)
  }

  return (
    <Modal
    show={isOpen}
    onHide={() => onClose()}
    title={`Select parts to import`}
    handleSubmit={handleSubmit}
    noFooter={false}
  >
      {data.map((value, index) => {
        return (
          <ModalSquare
            key={index}
            value={value}
            onUpdate={onUpdate}
          />
        );
      })}
      </Modal>
  );
}
