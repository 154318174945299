import { Checkbox20, CheckboxChecked20 } from "@carbon/icons-react";
import React from "react";

interface Props {
  selected: boolean;
}

export default function CsvModalCheckBox({ selected }: Props): JSX.Element {
  return (
    <>
      {selected ? (
        <CheckboxChecked20 width={40} height={40} />
      ) : (
        <Checkbox20 width={40} height={40} />
      )}
    </>
  );
}
