import React, { ReactNode } from "react";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import BodyEnd from "../placements/BodyEnd";

interface Props {
  children: ReactNode;
  handleSubmit: () => void;
  title: string | null;
  noFooter?: boolean;
  show:boolean;
  onHide: () => void;
}

export function Modal({
  children,
  handleSubmit,
  title,
  noFooter,
  show,
  onHide
}: Props): JSX.Element {

  return (<>{show && (
    <BodyEnd>
      <ContainerParent>
        <ControlContainer>
        <Container>
          <ModalHeader onHide={onHide} title={title} />
          <ContainerChildren>{children}</ContainerChildren>
        </Container>
        {!noFooter && <ModalFooter handleCancel={onHide} handleSubmit={handleSubmit} />}
        </ControlContainer>
      </ContainerParent>
    </BodyEnd>
  )}</>);
}

const ControlContainer = styled.div`
  position: relative;
  margin: 0 auto;
  top: 40%;
  transform: translate(0%, -25%);
  height:fit-content;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
`;

const Container = styled.div`
  max-height:600px;
  overflow-y: hidden;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
`;

const ContainerChildren = styled.div`
  margin: 20px;
`;


const ContainerParent = styled.div`
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
`;
