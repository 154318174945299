import { CalculationMethodType, LaborDifficultyType } from 'modules/product/components/labor/components/calculation/CalculationDefinition';
import { MaterialType } from 'type';

export interface ProductModel {
  name: string;
  sku: string;
  category: string;
  gender?: string;
  season?: string;
  developmentStatus?: string;
  id: string;
  misc?: MaterialContainerModel<MiscModel>[];
  productParts?: MaterialContainerModel<MaterialModel>[];
  labor?: {
    cost: number;
    type: LaborDifficultyType;
    calculationMethod: CalculationMethodType;
  };
  averageCost: number;
  marketPrice?: MarketPriceModel;
  imagePath?: string;
  vendors: ProductVendorsModel[];
  costType: CostType;
}

export enum CostType {
  AverageCost = 'Average Cost',
  RawMaterialCost = 'Raw Material Cost',
  PreferedVendorCost = 'Prefered Vendor Cost',
}

export interface ProductVendorsModel {
  name: string;
  country: string;
  id: string;
  vendorPrice: number;
  transferPrice: number;
  itemWeight: string;
  moq: string;
  status: string;
  isPreferred: boolean;
  note: string;
}

export interface MaterialContainerModel<T> {
  name: string;
  id: string;
  materials: T[];
  createdAt: Date;
}

export interface MiscModel {
  name: string;
  quantity: string;
  totalPrice: number;
  unitPrice: number;
  id: string;
}

export interface MaterialModel {
  name: string;
  weight: string;
  basePrice: string;
  totalPrice: string;
  type: MaterialType;
  volume: string;
  category: string;
  layerName: string;
  id: string;
  area?: string;
  density?: string;
}

export interface MarketPriceModel {
  wholesalePrice: number;
  retailPrice: number;
  useDefaultCalculation: boolean;
}
