import firebase from "firebase/app";
import 'firebase/auth';
import { useAppContext } from "core/contexts/AppContext";
import { Routes } from "AppRouter";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

export interface UseUserHooks {
  authStatus: AuthStatus;
  isLogged: boolean;
  loginWithEmail(email: string, password: string): Promise<void>;
  signOut(): void;
  user: firebase.User | null;
}

export enum AuthStatus {
  AUTH = "AUTH",
  NOT_AUTH = "NOT_AUTH",
  LOADING = "LOADING",
}

export function useUserHooks(): UseUserHooks {
  const { firebase } = useAppContext();
  const history = useHistory();
  const [user, setUser] = useState<firebase.User | null>(
    firebase!.auth().currentUser
  );
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.LOADING);

  useEffect(() => {
    firebase?.auth().onAuthStateChanged((user) => {
      if (!user) {
        setUser(null);
        setAuthStatus(AuthStatus.NOT_AUTH);
        return history.push(Routes.LOGIN);
      }

      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (user) {
      setAuthStatus(AuthStatus.AUTH);
    }
  }, [user]);

  const loginWithEmail = async (
    email: string,
    password: string
  ): Promise<void> => {
    try {
      const user = await firebase
        ?.auth() 
        .signInWithEmailAndPassword(email, password);
      if (user) {
        setUser(user.user!);
      }
    } catch (error) {
      // TODO
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log({ errorCode, errorMessage });
    }
  };

  const signOut = () => {
    return firebase
      ?.auth()
      .signOut()
      .then(() => history.push(Routes.LOGIN))
      .catch(console.error);
  };

  return {
    authStatus,
    isLogged: user ? true : false,
    loginWithEmail,
    signOut,
    user,
  };
}
