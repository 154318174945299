import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { Routes } from 'AppRouter';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { TrashCan20 } from '@carbon/icons-react/es';
import { DeleteModal } from 'modules/product/components/rawMaterial/components/tableMaterial/DeleteModal';

export function DeleteVendor(): JSX.Element {
  const [isDeleteMiscModalVisible, setDeleteMiscModalVisible] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const firebase = useFirebaseContext();
  const history = useHistory();

  const handleDelete = () => firebase.VendorService.deleteVendor(id).then(() => history.push(Routes.VENDORLIST));

  return (
    <Container>
      <DeleteButton onClick={() => setDeleteMiscModalVisible(true)}>
        <TrashCan20 color="#ffff" />
      </DeleteButton>
      <DeleteModal onClose={() => setDeleteMiscModalVisible(false)} isVisible={isDeleteMiscModalVisible} title="Delete vendor" onDelete={handleDelete} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const DeleteButton = styled.div`
  height: 100%;
  width: 40px;
  margin-left: 5px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

// const Container = styled.div`
//   display: block;
//   height: 40px;
//   width: 40px;
//   background-color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     cursor: pointer;
//   }
// `;
