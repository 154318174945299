import dayjs from "dayjs";
import { ContainerSection } from "components/common/table/styled";
import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { ProductFirestore } from "services/external/firebase/models/ProductFirestore";
import { TableComponent } from "modules/product/components/rawMaterial/components/tableMaterial/TableComponent";
import { Routes } from "AppRouter";
import { Image16 } from "@carbon/icons-react";
import styled from "styled-components";
import { SearchProductParameters } from "services/external/firebase/parameters/SearchProductParameters";
import firebase from "firebase/app";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { CategoryFirestore } from "services/external/firebase/models/CategoryFirestore";
type DocumentData = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
interface ProductTableData {
  sku: string;
  productName: string;
  lastUpdated: string;
  cost: string;
  marketWholesale: string;
  marketRetail: string;
  wholesaleMargin: string;
  retailMargin: string;
  id: string;
}

interface Props {
  queryData: (
    queryParam: SearchProductParameters
  ) => Promise<{
    products: ProductFirestore[];
    lastFetchedDoc: DocumentData;
    isPaginationDone: boolean;
  }>;
}

export function NewProductTable({ queryData }: Props): JSX.Element {
  const firebase = useFirebaseContext();
  const [categories, setCategories] = useState<CategoryFirestore[]>([]);

  useEffect(() => {
    getCategoriesFromFirebase();
  }, []);

  const getCategoriesFromFirebase = async () => {
    const fetchedCategories = await firebase.getCategories();
    console.log(fetchedCategories);
    setCategories(fetchedCategories);
    // const updatedProducts = products.map((p) => ({
    //   ...p,
    //   categoryName:
    //     fetchedCategories.find((c) => (c.id = p.category))?.name || "",
    // }));
    // setProducts(updatedProducts);
  };

  const [products, setProducts] = useState<ProductFirestore[]>([]);
  const { id } = useParams<{ id: string }>();
  const lastProductLoaded = useRef<DocumentData>();
  const [displayShowMore, setDisplayShowMore] = useState<boolean>(true);
  const paginationSize = 15;

  const history = useHistory();
  const navigateToProductPage = (productId: string) => {
    if (history) {
      history.push(Routes.PRODUCT.replace(":id", productId!));
    }
  };

  useLayoutEffect(() => {
    doSearch();
  }, []);

  const doSearch = async () => {
    const searchQueryParams: SearchProductParameters = {
      limit: paginationSize,
      lastSearchedDoc: lastProductLoaded.current,
    };

    const {
      products: list,
      lastFetchedDoc,
      isPaginationDone,
    } = await queryData(searchQueryParams);

    lastProductLoaded.current = lastFetchedDoc;
    if (isPaginationDone) {
      setDisplayShowMore(false);
    }
    setProducts([...products, ...list]);
  };

  const dataTable: ProductTableData[] = React.useMemo<ProductTableData[]>(
    () =>
      products.map((p) => {
        let wholesaleMarginPercentage = 0;
        let retailMarginPercentage = 0;
        if (!!p.marketPrice) {
          wholesaleMarginPercentage =
            ((p.marketPrice.wholesalePrice - parseFloat(p.cost)) /
              p.marketPrice.wholesalePrice) *
            100;
          retailMarginPercentage =
            ((p.marketPrice.retailPrice - parseFloat(p.cost)) /
              p.marketPrice.retailPrice) *
            100;
        }
        return {
          sku: p.sku,
          productName: p.name,
          categoryName: categories.find((c) => c.id == p.category)?.name || "",
          lastUpdated: dayjs.unix(p.updatedAt.seconds).format("MMMM DD, YYYY"),
          cost: p.cost.toString(),
          id: p.id,
          marketWholesale:
            !!p.marketPrice?.wholesalePrice &&
            p.marketPrice.wholesalePrice.toFixed(2),
          marketRetail: p.marketPrice && p.marketPrice.retailPrice.toFixed(2),
          wholesaleMargin: !!wholesaleMarginPercentage
            ? wholesaleMarginPercentage?.toFixed(2)
            : "-",
          retailMargin: !!retailMarginPercentage
            ? retailMarginPercentage.toFixed(2)
            : "-",
          icon: <Image16 style={{ color: "lightgrey" }} />,
        };
      }),
    [id, categories, products]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "icon",
        style: {
          width: "1%",
        },
      },
      {
        Header: "SKU",
        accessor: "sku",
        filterEnabled: true,
        style: {
          width: "9%",
        },
      },
      {
        Header: "Product Name",
        accessor: "productName",
        filterEnabled: true,
        style: {
          width: "20%",
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        style: {
          width: "20%",
        },
      },
      {
        Header: "Last Updated",
        accessor: "lastUpdated",
        style: {
          width: "25%",
          textAlign: "right",
        },
      },
      {
        Header: "Cost",
        subHeader: "$",
        accessor: "cost",
        style: {
          width: "5%",
          textAlign: "right",
        },
      },
      {
        Header: "Wholesale",
        subHeader: "$",
        accessor: "marketWholesale",
        style: {
          width: "5%",
          textAlign: "right",
        },
      },
      {
        Header: "Wholesale margin",
        subHeader: "%",
        accessor: "wholesaleMargin",
        style: {
          width: "5%",
          textAlign: "right",
        },
      },
      {
        Header: "Retail",
        subHeader: "$",
        accessor: "marketRetail",
        style: {
          width: "5%",
          textAlign: "right",
        },
      },
      {
        Header: "Retail margin",
        subHeader: "%",
        accessor: "retailMargin",
        style: {
          width: "5%",
          textAlign: "right",
        },
      },
      {
        Header: "ID",
        accessor: "id",
      },
    ],
    []
  );

  return (
    <StyledContainerSection>
      {products.length > 0 && (
        <TableComponent
          showMore={{
            enabled: displayShowMore,
            onClick: () => {
              doSearch();
            },
            increment: 1,
          }}
          onRowClick={(product) => navigateToProductPage(product.id)}
          hasItemOptions={false}
          data={dataTable}
          columns={columns}
        />
      )}
      {products.length == 0 && (
        <EmptyContainer>There are no product in this category</EmptyContainer>
      )}
    </StyledContainerSection>
  );
}

const StyledContainerSection = styled(ContainerSection)``;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.unabacusblack};
  min-height: 300px;
  font-size: 18px;
  background: #ffffff;
  margin: 1rem;
  font-style: bold;
`;
