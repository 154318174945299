import { Container } from "components/elements/Container";
import { ErrorLabel } from "components/elements/ErrorLabel";
import { Label } from "components/elements/Label";
import { Select } from "components/elements/Select";
import React from "react";
import { UseFormMethods } from "react-hook-form";

interface SelectContainerProps extends Partial<Pick<UseFormMethods, "register" | "errors">>{
  error?: string;
  label: string;
  placeholder?: string;
  value?: string;
  selectors: { value: string; name: string }[];
  name: string;
  black?: boolean;
  onChange?: (e: string) => void;
}

export const DropDown: React.FC<SelectContainerProps> = (props) => {
  return (
  <Container>
    <Label black={props.black}>{props.label}</Label>

    <Select
      onChange={(e) => !!props.onChange ? props.onChange(e.currentTarget.value) : null}
      black={props.black}
      error={!!props.error}
      defaultValue={props.value}
      ref={props.register}
      placeholder={props.placeholder}
      name={props.name}
    >
      <option
        value="disabled"
        disabled
        key="disabled"
        selected={true}
      >
        -
      </option>
      {props.selectors.map((val) => (
        <option
          selected={props.value === val.value}
          key={val.value}
          value={val.value}>
          {val.name}
        </option>
      ))}
    </Select>

    <ErrorLabel>{props.error}</ErrorLabel>
  </Container>
)};
