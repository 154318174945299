import { useEffect, useState } from "react";
import { useFirebaseContext } from "core/contexts/FirebaseContext";

export interface UseGetProductTotalPriceModel {
  totalPrice: number;
  totalBaseMaterialPrice: number;
  totalPlatingPrice: number;
  totalLaborPrice: number;
  totalMiscPrice: number;
  totalMaterialPartPrice: number;
}

export function useGetProductTotalPrice(
  id: string
): UseGetProductTotalPriceModel {
  const [totalLaborPrice, setTotalLaborPrice] = useState<number>(0);
  const [totalMiscPrice, setTotalMiscPrice] = useState<number>(0);
  const [totalMaterialPartPrice, setTotalMaterialPartPrice] = useState<number>(0);
  const [totalBaseMaterialPrice, setTotalBaseMaterialPrice] = useState<number>(0);
  const [totalPlatingPrice, setTotalPlatingPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const firebase = useFirebaseContext();

  useEffect(() => {
    const func = async () => {
      const unsubscribe = await firebase.getTotalPriceHook(id, d => {
          setTotalMaterialPartPrice(d.totalBaseMaterialPrice + d.totalPlatingPrice);
          setTotalMiscPrice(d.totalMiscPrice);
          setTotalLaborPrice(d.totalLaborPrice);
          setTotalPrice(d.totalPrice);
          setTotalBaseMaterialPrice(d.totalBaseMaterialPrice);
          setTotalPlatingPrice(d.totalPlatingPrice);
      } );
      return unsubscribe;
    }
    const unsub =  func();
    return () => unsub;
}, []);
console.log(totalPrice);
return { totalPrice, totalBaseMaterialPrice, totalPlatingPrice, totalMaterialPartPrice, totalLaborPrice, totalMiscPrice };
}
