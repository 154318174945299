import { Content, Header, HeaderMenuButton, HeaderName, SkipToContent } from 'carbon-components-react';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';

import { NavigationMenu } from './navigation/NavigationMenu';
import { StickyCalculatorComponent } from '../../modules/product/components/StickyCalculator/StickyCalculatorComponent';
import styled from 'styled-components';
import { LogoV2 } from 'styles/assets';

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props): JSX.Element {
  const location = useLocation();
  return (
    <>
      <StyledHeader aria-label="Miansai">
        <SkipToContent />
        <HeaderMenuButton aria-label="Open menu" />
        <div className="logo-container">
          <CenterImage src={LogoV2} alt="logo" style={{ height: 'auto', width: '15px' }}></CenterImage>
        </div>

        <HeaderName href="#" prefix="Miansai">
          <></>
        </HeaderName>
        <NavigationMenu />
      </StyledHeader>
      <StyledLayout>{children}</StyledLayout>
      {location.pathname.split('/').includes('product') && <StickyCalculatorComponent />}
    </>
  );
}

const StyledHeader = styled(Header)`
  background-color: ${(props) => props.theme.colors.lightColor};
  border-bottom: none;
  .logo-container {
    width: 140px;
  }
`;

const CenterImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  vertical-align: middle;
`;
const StyledLayout = styled(Content)`
  margin-left: 105px !important;
  height: 100vh;
  padding-top: 0 !important;
  padding-bottom: 60px;
  background-color: #f3f3f3 !important;
  padding-right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
