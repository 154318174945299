import React, { useEffect, useState } from 'react';

import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useParams } from 'react-router';
import { Button } from 'components/elements/buttons/Button';
import { FormInputContainer } from 'components/common/FormInputContainer';
import { DropDown } from 'components/common/Dropdown';
import { CategoryFirestore } from 'services/external/firebase/models/CategoryFirestore';
import styled from 'styled-components';
import { developmentStatus } from 'core/data/developmentStatus';
import { genderList } from 'core/data/genders';
import { CostType } from 'services/domain/products/models/ProductModel';

interface ProductInformationForm {
  name: string;
  internalId: string;
  category: string;
  sku: string;
  averageCost: string;
  gender: string;
  season: string;
  developmentStatus: string;
  costType: CostType;
}

interface Props {
  defaultValue: ProductInformationForm;
}

export const ProductInformationForms: React.FC<Props> = (props) => {
  const firebase = useFirebaseContext();
  const [categories, setCategories] = useState<CategoryFirestore[]>([]);
  const { id } = useParams<{ id: string }>();
  // const [isAverageCostEnabled, setIsAverageCostEnabled] = useState<boolean>(props.defaultValue.isAverageCostEnabled);
  const [costType, setCostType] = useState<CostType>(props.defaultValue.costType);
  useEffect(() => {
    getCategoriesFromFirebase();
  }, []);
  const getCategoriesFromFirebase = async () => {
    const fetchedCategories = await firebase.getCategories();

    setCategories(fetchedCategories);
  };

  interface FormElements extends HTMLFormControlsCollection {
    nameInput: HTMLInputElement;
    skuInput: HTMLInputElement;
    categoryInput: HTMLInputElement;
    developmentStatusInput: HTMLInputElement;
    genderInput: HTMLInputElement;
    seasonInput: HTMLInputElement;
    idInput: HTMLInputElement;
    averageCostInput: HTMLInputElement;
    costTypeInput: HTMLInputElement;
  }
  interface UsernameFormElement extends HTMLFormElement {
    readonly elements: FormElements;
  }

  function handleSubmitNew(event: React.FormEvent<UsernameFormElement>) {
    event.preventDefault();
    const {
      nameInput,
      skuInput,
      categoryInput,
      developmentStatusInput,
      genderInput,
      seasonInput,
      averageCostInput,
      costTypeInput,
    } = event.currentTarget.elements;
    console.log(costTypeInput.value);
    firebase.ProductService.updateProduct(id, {
      name: nameInput.value,
      sku: skuInput.value,
      category: categoryInput.value,
      categoryName: categoryInput.id,
      developmentStatus: developmentStatusInput.value,
      gender: genderInput.value,
      season: seasonInput.value,
      costType: CostType[costTypeInput.value],
      averageCost: parseFloat(averageCostInput.value),
      id: id,
      // calculationMode: {
      //   isAverageCostEnabled: isAverageCostEnabled,
      //   averageCost: isAverageCostEnabled ? parseFloat(averageCostInput.value) : parseFloat(props.defaultValue.averageCost),
      // },
    });
  }

  return (
    <StyledProductInformationForm>
      <form onSubmit={handleSubmitNew}>
        <div className="flexColumn">
          <FormInputContainer type="text" name="nameInput" placeholder={props.defaultValue.name} label="Name" value={props.defaultValue.name} black={true} />
          <FormInputContainer
            value={props.defaultValue.internalId}
            disabled={true}
            label="Internal-ID"
            type="text"
            name="internalId"
            placeholder=""
            black={true}
          />

          <FormInputContainer type="text" name="skuInput" placeholder="" label="Sku" value={props.defaultValue.sku} black={true} />
          <DropDown
            onChange={(val) => setCostType(CostType[val])}
            value={props.defaultValue.costType}
            label="Cost"
            name="costTypeInput"
            selectors={Object.entries(CostType).map(([key, value]) => ({
              name: value,
              value: key,
            }))}
            black={true}
          />
          <FormInputContainer
            pattern="^(\d*\.)?(\d+)?$"
            value={props.defaultValue.averageCost}
            disabled={costType != CostType.AverageCost}
            label=""
            type="text"
            name="averageCostInput"
            placeholder=""
            black={true}
          />
        </div>
        <div className="flexColumn">
          <DropDown
            value={props.defaultValue.category}
            label="Category"
            name="categoryInput"
            selectors={categories.map((categorie) => ({
              name: categorie.name,
              value: categorie.id,
            }))}
            black={true}
          />
          <DropDown
            value={props.defaultValue.gender}
            label="Gender"
            name="genderInput"
            selectors={genderList.map((gender) => ({
              name: gender,
              value: gender,
            }))}
            black={true}
          />
          <FormInputContainer type="text" name="seasonInput" placeholder="" label="Season" value={props.defaultValue.season} black={true} />
          <DropDown
            value={props.defaultValue.developmentStatus}
            label="Development Status"
            name="developmentStatusInput"
            selectors={developmentStatus.map((status) => ({
              name: status,
              value: status,
            }))}
            black={true}
          />
          <ActionBar>
            <Button type="submit">Submit</Button>
          </ActionBar>
        </div>
      </form>
    </StyledProductInformationForm>
  );
};

const StyledProductInformationForm = styled.div`
  form {
    display: flex;
    flex-direction: row;
    input:not([type='checkbox']) {
      width: 265px;
    }
  }
  .flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    :first-child {
      padding-right: 2rem;
    }
    div:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const ActionBar = styled.span`
  display: flex;
  flex-direction: row;
  button:first-child,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  div {
    background-color: ${(prop) => prop.theme.colors.mainColorLight};
    color: ${(prop) => prop.theme.colors.mainColor};
  }
`;
