import React from 'react';

import { JewelrySpecificationModel } from 'services/domain/products/models/specifc/JewelryProductModel';
import styled from 'styled-components';
import { defaultIfNullOrEmpty } from 'core/utilities/string';

interface Props {
  specification?: JewelrySpecificationModel;
}

export const JewelrySpecificationsTable: React.FC<Props> = ({ specification = {} }) => {
  const {
    baseMetal,
    length,
    caratWeight,
    claspSize,
    fit,
    finish,
    height,
    nrfColorCode,
    material,
    gauge,
    nrfSize,
    size,
    platingMetal,
    width,
    stoneCount,
  } = specification;
  return (
    <StyledJewelrySpecificationTable>
      <div className="flexColumn">
        <div className="flexContent">
          <label>Base Metal</label>
          <span>{defaultIfNullOrEmpty(baseMetal)}</span>
        </div>
        <div className="flexContent">
          <label>Plating Metal</label>
          <span>{defaultIfNullOrEmpty(platingMetal)}</span>
        </div>
        <div className="flexContent">
          <label>Color/Finish</label>
          <span>{defaultIfNullOrEmpty(finish)}</span>
        </div>
      </div>

      <div className="flexColumn">
        <div className="flexContent">
          <label>Length</label>
          <span>{defaultIfNullOrEmpty(length)}</span>
        </div>
        <div className="flexContent">
          <label>Height</label>
          <span>{defaultIfNullOrEmpty(height)}</span>
        </div>
        <div className="flexContent">
          <label>Width</label>
          <span>{defaultIfNullOrEmpty(width)}</span>
        </div>
      </div>

      <div className="flexColumn">
        <div className="flexContent">
          <label>Fit</label>
          <span>{defaultIfNullOrEmpty(fit)}</span>
        </div>
        <div className="flexContent">
          <label>Size</label>
          <span>{defaultIfNullOrEmpty(size)}</span>
        </div>
      </div>

      <div className="flexColumn">
        <div className="flexContent">
          <label>NRF Color Code</label>
          <span>{defaultIfNullOrEmpty(nrfColorCode)}</span>
        </div>
        <div className="flexContent">
          <label>NRF Size</label>
          <span>{defaultIfNullOrEmpty(nrfSize)}</span>
        </div>
      </div>

      <div className="flexColumn">
        <div className="flexContent">
          <label>Gauge</label>
          <span>{defaultIfNullOrEmpty(gauge)}</span>
        </div>
        <div className="flexContent">
          <label>Carat Weight</label>
          <span>{defaultIfNullOrEmpty(caratWeight)}</span>
        </div>
        <div className="flexContent">
          <label>Material</label>
          <span>{defaultIfNullOrEmpty(material)}</span>
        </div>
      </div>

      <div className="flexColumn">
        <div className="flexContent">
          <label>Clasp Size</label>
          <span>{defaultIfNullOrEmpty(claspSize)}</span>
        </div>
        <div className="flexContent">
          <label>Stone Count</label>
          <span>{defaultIfNullOrEmpty(stoneCount)}</span>
        </div>
      </div>
    </StyledJewelrySpecificationTable>
  );
};

export const StyledJewelrySpecificationTable = styled.div`
  background-color: white;
  display: flex;
  width: 100%;

  .flexColumn:first-of-type {
    border-left: 1px solid black;
  }

  .flexColumn {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    width: 100%;
    .flexContent {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;

      label {
        padding: 0.5rem 0;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;
