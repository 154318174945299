import { Redirect } from "react-router";

function App(): JSX.Element {
 
  return (
    <Redirect to={{ pathname: "/catalog"}} />
  );
}

export default App;
