import React from "react";
import styled from "styled-components";

interface Props {
  children: string;
  callback: () => void;
}

export default function PrimaryButton({
  children,
  callback,
}: Props): JSX.Element {
  const handleClick = () => {
    callback();
  };

  return (
    <Container className="primary-button" onClick={handleClick}>
      {children}
    </Container>
  );
}

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${(props) => props.theme.colors.mainColor};
  border: 0;
  width: 170px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
`;
