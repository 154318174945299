import { MaterialFirestore, MiscCollection } from 'type';
import React, { useEffect, useState } from 'react';

import Collapser from 'components/common/Collapser';
import { ContainerSection } from 'components/common/table/styled';
import { HeaderLayout } from 'components/common/HeaderLayout';
import ProductHeader from 'modules/product/components/ProductHeader';
import { LaborView } from 'modules/product/components/labor/LaborView';
import { Layout } from 'components/common/Layout';
import { MiscPartView } from 'modules/product/components/rawMaterial/components/misc/MiscPartView';
import { ProductInformationForms } from 'modules/product/components/productInformations/ProductInformationsForms';
import styled from 'styled-components';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useParams } from 'react-router';
import { useGetProductByIdSnapshot } from 'core/hooks/firebase/products/useGetProductById';
import { ProductBackButton } from 'modules/product/components/ProductBackButton';
import { ProductPartsView } from 'modules/product/components/rawMaterial/components/productParts/ProductPartsView';
import { AddRawMaterialPart } from 'modules/product/components/rawMaterial/components/productParts/add/addRawMaterialPart';
import { MarketPriceView } from 'modules/product/components/marketPrice/MarketPriceView';
import { MaterialContainerModel, MaterialModel } from 'services/domain/products/models/ProductModel';
import { Tabs } from 'carbon-components-react';
import TabList from 'components/common/TabList';
import { ProductInformationImage } from 'modules/product/components/productInformations/ProductInformationImage';
import Divider from 'components/common/Divider';
import { JewelrySpecificationsTable } from 'modules/product/components/clientsSpecific/jewelry/JewelrySpecificationsTable';
import { ProductVendorsView } from 'modules/product/components/vendor/ProductVendorsView';
import { ContainerComponent } from 'components/elements/Container';
import { SpecificationUpload } from 'modules/product/components/clientsSpecific/jewelry/SpecificationUpload';

export function ProductPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const firebase = useFirebaseContext();
  const { product } = useGetProductByIdSnapshot(id);

  const [productParts, setProductParts] = useState<MaterialContainerModel<MaterialModel>[]>([]);
  const [misc, setMisc] = useState<MiscCollection[]>([]);

  useEffect(() => {
    if (!!product) {
      getProductParts();
      getMisc();
    }
  }, [product]);

  const getProductParts = async () => {
    const productPartsValues = Object.values(product.productParts);
    setProductParts(productPartsValues);
  };

  const getMisc = async () => {
    const misc = Object.values(product.misc);
    setMisc(misc);
  };
  const handleProductPartSubmit = (partName: string) => {
    const materialParts: MaterialFirestore = {
      name: partName,
      id: '',
      materials: [],
      createdAt: new Date(),
    };
    firebase?.ProductService.addProductPartToProduct(materialParts, id);
    getProductParts();
  };

  const handleMiscPartSubmit = (partName: string) => {
    const object: MiscCollection = {
      materials: [],
      name: partName,
      id: id,
      createdAt: new Date(),
    };
    firebase?.ProductService.addMiscPartToProduct(id, object);
    getMisc();
  };

  const handleImageUploadDone = (fileName: string) => {
    firebase.ProductService.updateImagePath(id, fileName);
  };

  return (
    <Layout>
      {product && (
        <>
          <HeaderLayout title={<ProductBackButton {...product} />} rightComponent={<ProductHeader />} />
          <Divider />
          <Collapser title="Product information">
            <ProductInformationSection>
              <StyledProductInformationBlock>
                <ProductInformationImage onUploadDone={handleImageUploadDone} imagePath={product.imagePath}></ProductInformationImage>
              </StyledProductInformationBlock>
              <StyledProductInformationBlock>
                <ProductInformationForms
                  defaultValue={{
                    developmentStatus: product.developmentStatus,
                    gender: product.gender,
                    season: product.season,
                    category: product.category,
                    internalId: product.id,
                    name: product.name,
                    sku: product.sku,
                    averageCost: product?.averageCost?.toFixed(2) || '',
                    costType: product.costType,
                  }}
                />
              </StyledProductInformationBlock>
            </ProductInformationSection>
          </Collapser>
          <TabList>
            <ContainerSection title="Specifications">
              <ComponentSection>
                <JewelrySpecificationsTable specification={product.specification} />
                <ContainerComponent>
                  <h4>Documents</h4>
                  <h5>Specifications images </h5>
                  <div className="subtitle">This file will be use to generate a global export of your product. Use ratio of 1:1.</div>
                  <SpecificationUpload imagePath={product.specification.imagePath}></SpecificationUpload>
                </ContainerComponent>
              </ComponentSection>
            </ContainerSection>
            <ContainerSection title="RAW Materials">
              {productParts &&
                productParts.map((value, index) => {
                  return (
                    <ProductPartsView
                      key={value.name + index}
                      title={value.name}
                      data={value.materials}
                      docId={value.id}
                      productId={id}
                      callback={getProductParts}
                    />
                  );
                })}

              {misc &&
                misc.length > 0 &&
                misc.map((value, index) => {
                  return <MiscPartView key={value.name + index} title={value.name} data={value.materials} docId={value.id} productId={id} callback={getMisc} />;
                })}
              <AddRawMaterialPart placeholder="Add New Product Part" onSubmit={handleProductPartSubmit} />
              <AddRawMaterialPart placeholder="Add Misc Part" onSubmit={handleMiscPartSubmit} />
            </ContainerSection>
            <ContainerSection title="Labor">
              <ContainerSection>
                <LaborView product={product} />
              </ContainerSection>
            </ContainerSection>
            <ContainerSection title="Market Price">
              <MarketPriceView product={product} />
            </ContainerSection>
            <ContainerSection title="Vendor">
              <ProductVendorsView productVendors={product.vendors} />
            </ContainerSection>
          </TabList>
        </>
      )}
    </Layout>
  );
}

const ComponentSection = styled.div`
  display: block;
  margin-bottom: 30px;

  > * {
    margin-bottom: 20px;
  }
`;

const ProductInformationSection = styled.div`
  background-color: #000000;
  padding: 1rem;
  width: 100%;
  display: flex;
`;

const StyledProductInformationBlock = styled.div`
  background: white;
  padding: 20px;
  margin: 20px;
  min-width: 300px;
  min-height: 300px;
`;

export const StyledTabs = styled(Tabs)`
  background-color: #fff;
`;

export const StyledCollapser = styled.div`
  CollapseContent {
    background-color: #1e1e1e;
  }
`;

export const SectionHeader = styled.div`
  width: 80px;
  width: 100%;
  background-color: #fff;
`;
