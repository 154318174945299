import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import { useParams } from "react-router";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { MarketPriceDataModel } from "type";
import { SimpleInputContainer } from "components/common/SimpleInputContainer";
import { ProductModel } from "services/domain/products/models/ProductModel";

interface Prop {
  product: ProductModel;
}

export interface MarketPriceState {
  wholesalePrice: string,
  retailPrice: string
}

interface MarketMarginPercentageState {
  wholesale: number,
  retail: number
}

const initialMarketPriceState: MarketPriceState = {
  wholesalePrice: "0.00",
  retailPrice: "0.00"
}

const initialMarketMarginPercentageState: MarketMarginPercentageState = {
  wholesale: 0,
  retail: 0
}

export const MarketPriceView = ({product} : Prop): JSX.Element => {
  const firebase = useFirebaseContext();
  const {id} = useParams<{id: string}>();
//  const isInit = useRef(true);
  // const reducer = (state, action) => {
  //   switch (action) {
  //     case 'UPDATE_MARKET_PRICE' :
  //       return
  //   }
  // }
  const {totalPrice} = firebase.calculateTotalPrice2(product);
  const [marketPrice, setMarketPrice] = useState<MarketPriceState>(initialMarketPriceState);
  const [useDefaultCalculation, setUseDefaultCalculation] = useState<boolean>(true);
  const [marketMarginPercentage, setMarketMarginPercentage] = useState<MarketMarginPercentageState>(initialMarketMarginPercentageState);

  useEffect(() => {
    if(!!id) {
      const getMarketPrice = async () => {
        const {retailPrice, useDefaultCalculation, wholesalePrice} = await firebase.ProductService.getMarketPriceByProductId(id);
        setUseDefaultCalculation(useDefaultCalculation);
        setMarketPrice({retailPrice: retailPrice.toFixed(2), wholesalePrice: wholesalePrice.toFixed(2)})
        console.log(retailPrice);
      };
      getMarketPrice();
    }
  }, [id])

  useEffect(() => {
    if(!!totalPrice && useDefaultCalculation) {
      const wholesalePrice = totalPrice * 4
      const retailPrice = wholesalePrice * 2.5;
      setMarketPrice({
        wholesalePrice: wholesalePrice.toFixed(2,),
        retailPrice: retailPrice.toFixed(2)
      })
    }
  }, [totalPrice, useDefaultCalculation, product])

  useEffect(() => {
    if(!!totalPrice) {
      const wholesalePriceNumber = parseFloat(marketPrice.wholesalePrice);
      const retailPriceNumber = parseFloat(marketPrice.retailPrice);
      const wholesaleMarginPercentage = ((wholesalePriceNumber - totalPrice) / wholesalePriceNumber )* 100
      const retailPrice =  ((retailPriceNumber - totalPrice) / retailPriceNumber )* 100
      setMarketMarginPercentage({
        wholesale: Math.round(wholesaleMarginPercentage),
        retail: Math.round(retailPrice)
      })
    }
  }, [marketPrice, product])

  useMemo(() => {
    if(marketPrice.wholesalePrice != initialMarketPriceState.wholesalePrice && marketPrice.retailPrice != initialMarketPriceState.retailPrice ) {
      const marketPriceDataModel: MarketPriceDataModel = ({
        retailPrice: parseFloat(marketPrice.retailPrice),
        wholesalePrice: parseFloat(marketPrice.wholesalePrice),
        useDefaultCalculation: useDefaultCalculation
      })
      firebase.ProductService.updateMarketPrice(id, marketPriceDataModel)
    }
    
  }, [marketPrice.retailPrice, marketPrice.wholesalePrice]) ;
  return (
    <Container>
     <StyledMarketViewComponent>
       <h4>Market Parameters</h4>
       <input type="checkbox" checked={useDefaultCalculation} onChange={() => setUseDefaultCalculation(!useDefaultCalculation)}/> Use default calculation
       <hr/>

       <SimpleInputContainer
        pattern="^(\d*\.)?(\d+)?$"
        type="text"
        disabled={useDefaultCalculation}
        name="wholesalePrice"
        placeholder=""
        label="Wholesale Price"
        value={marketPrice.wholesalePrice}
        black={true}
        highlightValue={true}
        onInputChange={(val) => setMarketPrice({...marketPrice, wholesalePrice: val})}
      />
       <div className='subtitle'>
        You will have a margin of <b>{marketMarginPercentage.wholesale}%</b>
      </div>
      <SimpleInputContainer
        pattern="^(\d*\.)?(\d+)?$"
        value={marketPrice.retailPrice}
        disabled={useDefaultCalculation}
        label="Retail Price"
        type="text"
        name="retailPrice"
        placeholder=""
        black={true}
        highlightValue={true}
        onInputChange={(val) => setMarketPrice({...marketPrice, retailPrice: val})}
      />
       <div className='subtitle'>
        You will have a margin of <b>{marketMarginPercentage.retail}%</b>
      </div>
    
    </StyledMarketViewComponent>
    </Container>
  );
}

const Container = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: left;
`;

export const StyledMarketViewComponent = styled.div`
background: white;
padding: 15px;
max-width:auto;

h4 {
  padding-bottom: 15px;
}

hr {
  border-top: 1px solid lightgray;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.subtitle{
  width:260px;
  font-style: oblique;
  font-size: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
`;