import { Header, Misc } from 'type';

import { AddMisc } from './modal/AddMisc';
import React, { ReactText, useState } from 'react';
import styled from 'styled-components';
import { AddButton } from 'components/common/buttons/AddButton';
import { DeleteModal } from '../tableMaterial/DeleteModal';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useParams } from 'react-router';
import { DeleteTableButton } from 'components/common/table/DeleteTable';

interface Props {
  children: ReactText;
  LayerName: string;
  docId: string;
  callback: () => void;
  data: Misc[];
}

export function MiscViewHeader({ children, LayerName, data, docId, callback }: Props): JSX.Element {
  const [name, setName] = useState<string>(children.toString());
  const [isAddMiscModalVisible, setIsAddMiscModalVisible] = useState<boolean>(false);
  const [isDeleteMiscModalVisible, setDeleteMiscModalVisible] = useState<boolean>(false);
  const firebase = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      firebase.ProductService.updateMiscName(id, docId, name);
    }
  };

  const onMiscDelete = () => {
    firebase.ProductService.deleteMisc(id, docId);
    callback();
  };

  return (
    <TitleContainer>
      <TitleText value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(event) => handleKeyDown(event)} />
      <ContainerButtons>
        <AddButton onClick={() => setIsAddMiscModalVisible(true)}>Add Misc</AddButton>
        <AddMisc
          callback={callback}
          docId={docId}
          data={data}
          layerName={LayerName}
          onClose={() => setIsAddMiscModalVisible(false)}
          isVisible={isAddMiscModalVisible}
        />
        <DeleteTableButton onClick={() => setDeleteMiscModalVisible(true)} />
        <DeleteModal onClose={() => setDeleteMiscModalVisible(false)} isVisible={isDeleteMiscModalVisible} title="Delete misc" onDelete={onMiscDelete} />
      </ContainerButtons>
    </TitleContainer>
  );
}

const TitleContainer = styled.div`
  width: 100%;
  height: 70px;
  flex-direction: row;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const TitleText = styled.input`
  font-size: 18px;
  border: none;

  &:focus {
    outline: none;
  }
`;
