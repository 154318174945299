import { useFirebaseContext } from "core/contexts/FirebaseContext";
import React, { useState } from "react";
import styled from "styled-components";
import { Misc } from "type";
import SearchField, { ProductBase } from "../../../../forms/SearchField";
import { useParams } from "react-router";
import { Modal } from "components/common/modals/Modal";
import { uuidv4 } from "core/utilities/uuid";
import ProductPartListElement from "../../productParts/add/ProductPartListElement";

interface Props {
  layerName: string;
  data: Misc[];
  docId: string;
  callback: () => void;
  isVisible: boolean;
  onClose: () => void;
  productType: string;
  miscList: ProductBase[]
}

export function AddMiscPartModal({
  data,
  docId,
  callback,
  onClose,
  isVisible,
  productType,
  miscList
}: Props): JSX.Element {
    const [state, setstate] = useState<Misc[]>([]);
    const firebase = useFirebaseContext();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { id } = useParams<{ id: string }>();

  const results = !searchTerm ?
  miscList : 
  miscList.filter(product => product.Name.toLowerCase().includes(searchTerm.toLowerCase()))

  const handleSubmit = () => {
    state.map((value) => {
      const list = [...data, value];
      firebase?.ProductService.updateMiscs(id, docId, list);
    });
    isVisible=false;
    callback();
  };

  const handleClick = (name: string, price: string) => {
    const item: Misc = {
      name: name,
      quantity: "1",
      totalPrice: parseFloat(price),
      unitPrice: parseFloat(price),
      id: uuidv4()
    };
    setstate([...state, item]);
  };

  return (
    <>
     {isVisible && (
    <Modal
      show={isVisible}
      onHide={() => onClose()}
      title={`Add ${productType}`}
      handleSubmit={handleSubmit}
      noFooter={false}
    >
    <>
      <SearchField
        handleChange={setSearchTerm}
      />
      <ContainerList>
      {results.sort((a,b)=>a.Name > b.Name ? 1 : -1).map((value, index) => {
          return <ProductPartListElement handleClick={handleClick} key={index} {...value} />;
        })}
      </ContainerList>
      </>
     </Modal>)}
     </>
  );
}
const ContainerList = styled.div`
height: auto;
display: flex;
flex-direction: column;
justify-content: space-between;
max-height: 400px;
overflow-y: auto;
`;
