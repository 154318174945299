import React from "react";
import styled from "styled-components";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

interface Props {
  handleSubmit: () => void;
  handleCancel: () => void;
}

export default function ModalFooter({
  handleCancel,
  handleSubmit,
}: Props): JSX.Element {
  return (
    <Container>
      <SecondaryButton callback={handleCancel}>Cancel</SecondaryButton>
      <PrimaryButton callback={handleSubmit}>Submit</PrimaryButton>
    </Container>
  );
}

const Container = styled.div`
  height: 80px;
  width: "100%";
  margin: 0 20px;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;

  > button:first-child {
    margin-right: 1rem;
  }
`;
