import React from 'react';

import styled from 'styled-components';
import { LaborCalculationView } from './components/calculation/LaborCalculationView';
import { ProductModel } from 'services/domain/products/models/ProductModel';

interface Prop {
  product: ProductModel;
}

export const LaborView = ({ product }: Prop): JSX.Element => {
  return (
    <Container>
      <LaborCalculationView product={product} />
    </Container>
  );
};

const Container = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: left;
`;
