import React, { useContext } from "react";
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeContext,
  ThemeProvider,
} from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      backgroundGreyLight: string;
      blackLight: string;
      borderLightgrey: string;
      lightColor: string;
      lightGray: string;
      mainColor: string;
      mainColorLight: string;
      accentColor: string
      unabacusblack: string;
      white: string;
    };
  }
}

const theme: DefaultTheme = {
  colors: {
    backgroundGreyLight: "#f2f2f2",
    blackLight: "#353535",
    borderLightgrey: "#ececec",
    lightColor: "#6031b7",
    lightGray: "#8c8c8c",
    mainColor: "#783de5",
    mainColorLight: "rgba(120, 61, 229, 0.05)",
    accentColor: "#6031b7",
    unabacusblack: "#333333",
    white: "#fff"
  },
};

export const GlobalStyle = createGlobalStyle`
  .ReactCollapse--collapse {
    transition: height 290ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }

  .bx--side-nav__link-text {
    color: #fff !important;
  }

  .bx--content {
      height: auto !important;
      min-height: 100vh;
      max-width: 100vw;
  }
  #root {
      overflow-x: hidden;
      font-family: 'ArchiaRegular', sans-serif;
  }
  @keyframes fadein {
    0%{ opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fadeout {
    0%{ opacity: 1; }
    100% { opacity: 0; }
  }
`;

export const AppThemeProvider: React.FC = (props) => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

export function useAppTheme(): DefaultTheme {
  return useContext(ThemeContext);
}
