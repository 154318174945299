import { HeaderButton } from 'components/common/buttons/HeaderButton';
import React from 'react';
import { CSVReader } from 'react-papaparse';
import { File } from './ProductCsvImportComponent';

interface Props {
  handleImport: (param: File[]) => void;
}

export function CSVImport(props: Props): JSX.Element {
  const buttonRef = React.createRef<CSVReader>();

  const handleOnDrop = (params: File[]) => {
    props.handleImport(params);
  };

  const handleOnError = (params: File[]) => {
    console.log(params);
  };

  const handleOpen = (e: React.MouseEvent) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  return (
    <CSVReader ref={buttonRef} onFileLoad={handleOnDrop} onError={handleOnError} noClick noDrag noProgressBar>
      {() => <HeaderButton onClick={(e) => handleOpen(e)}>Import Rhino file</HeaderButton>}
    </CSVReader>
  );
}
