import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { defaultIfNullOrEmpty } from 'core/utilities/string';
import { JewelryProductModel } from 'services/domain/products/models/specifc/JewelryProductModel';
import image from '../../styles/assets/brands/miansai.png';
import RobotoRegular from '../../styles/assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../styles/assets/fonts/Roboto-Bold.ttf';

interface Props {
  product: JewelryProductModel;
  specImageLink: string;
}
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    color: '#373A3C',
    padding: 30,
    paddingBottom: 60,
    fontSize: 10,
    flexDirection: 'column',
    fontFamily: 'Roboto',
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  // border: {
  //   border: '1 solid #C5CDCF',
  //   borderRadius: '2',
  // },
  greyBackground: {
    backgroundColor: '#E5E5E5',
  },
  link: {
    color: '#1b96c3',
    textDecoration: 'underline',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: 5,
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    width: '100%',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    marginTop: 10,
  },
  containerHeader: {
    flexDirection: 'row',
    borderBottomColor: '#F4F5F7',
    backgroundColor: '#F4F5F7',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 27,
    textAlign: 'left',
    flexGrow: 1,
    fontWeight: 700,
  },
  tablePadding: {
    paddingHorizontal: 5,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    position: 'absolute',
    bottom: 30,
    left: 30,
    fontWeight: 400,
    fontSize: 8,
    color: '#98A1A5',
    marginTop: 10,
    width: '100%',
  },
  flexRow: {
    // borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    // margin: '25px 0',
    // alignItems: 'flex-start',
    flex: '0 0 auto ',
    justifyContent: 'space-between',
  },
  flexRowFixedHeight: {
    display: 'flex',
    flexDirection: 'row',
    padding: '25px 0',
    height: '200px',
    alignItems: 'flex-start',
    flex: '0 0 auto ',
    justifyContent: 'flex-end',
  },
  flexRowHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '25px 0 25px 0',
    alignItems: 'flex-start',
    flex: '0 0 auto ',
    justifyContent: 'flex-end',
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    height: '50px',
    width: '200px',

    // padding: '40px 0',
  },
  label: {
    color: 'red',
    padding: '1px 0',
    fontWeight: 'bold',
  },
  logo: {
    width: '130px',
    height: '50px',
    marginTop: '-20px',
  },
  logo2: {},
  border: {
    //  borderBottom: '1px solid black',
  },
  image: {
    width: '200px',
    height: '200px;',
  },
  flexContainer: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '50px',
  },
});

export const SpecificationExport = ({ product, specImageLink }: Props): JSX.Element => {
  const currentDate = new Date().toLocaleDateString('en-US');
  const productSpecifications = product.specification;
  const { sku, season, gender } = product;
  const {
    baseMetal,
    length,
    caratWeight,
    claspSize,
    fit,
    finish,
    height,
    nrfColorCode,
    material,
    gauge,
    nrfSize,
    size,
    platingMetal,
    width,
    stoneCount,
    imagePath,
  } = productSpecifications;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.flexContainer}>
          <View style={styles.flexRow}>
            <View style={styles.flexContent}>
              <Image style={styles.logo} src={image} />
            </View>
            <View style={styles.flexContent}>
              <Text></Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>Confidential</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Season:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(season, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Date:</label>
              </Text>
              <Text>{currentDate}</Text>
            </View>
          </View>
          <View style={styles.border} />
          <View style={styles.flexRow}>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>SKU:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(sku, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Style Name:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty('', '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Gender:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(gender, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Type:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty('', '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Size:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(size, '??')}</Text>
            </View>
          </View>
          <View style={styles.border} />
          <View style={styles.flexRow}>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Base Metal:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(baseMetal, '??')}</Text>
            </View>

            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Plating:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(platingMetal, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Plating Grade:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty('', '??')}</Text>
            </View>

            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Finish:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(finish, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Weight:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(caratWeight, '??')}</Text>
            </View>
          </View>

          <View style={styles.border} />
          <View style={styles.flexRow}>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Karat count:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty(stoneCount, '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text style={styles.bold}>
                <label>Comment:</label>
              </Text>
              <Text>{defaultIfNullOrEmpty('', '??')}</Text>
            </View>
            <View style={styles.flexContent}>
              <Text></Text>
            </View>
            <View style={styles.flexContent}>
              <Text></Text>
            </View>
            <View style={styles.flexContent}>
              <Text></Text>
            </View>
          </View>
        </View>

        {specImageLink && (
          <View wrap={false} style={{ flex: 1 }}>
            <Image
              style={{
                width: 'auto',
              }}
              src={specImageLink}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};
// https://firebasestorage.googleapis.com/v0/b/unabacus-dev.appspot.com/o/miansai%2Fproduct-specifica-tions%2FScreen%20Shot%202022-01-15%20at%2011.40.43%20AM.png?alt=media&to-ken=7280fabf-941d-4030-ba6f-f25af0604dab
// https://firebasestorage.googleapis.com/v0/b/unabacus-dev.appspot.com/o/miansai%2Fproduct-specifications%2FScreen%20Shot%202022-01-15%20at%2011.40.43%20AM.png?alt=media&token=7280fabf-941d-4030-ba6f-f25af0604dab
