import { AddButton } from 'components/common/buttons/AddButton';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { AddRawMaterial } from 'modules/product/components/rawMaterial/components/productParts/add/addRawMaterialForm';
import React, { ReactText, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { AddPlating } from './add/addPlatingForm';
import { DeleteModal } from '../tableMaterial/DeleteModal';
import { DeleteTableButton } from 'components/common/table/DeleteTable';
import { MaterialModel } from 'services/domain/products/models/ProductModel';

interface Props {
  children: ReactText;
  LayerName: string;
  data: MaterialModel[];
  docId: string;
  callback: () => void;
}

export function ProductPartsViewHeader({ children, LayerName, data, docId, callback }: Props): JSX.Element {
  const [name, setName] = useState<string>(children.toString());
  const [isPlatingVisible, setIsPlatingVisible] = useState<boolean>(false);
  const [isRawMaterialVisible, setIsRawMaterialVisible] = useState<boolean>(false);
  const [isDeleteProductPartModalVisible, setDeleteProductPartModalVisible] = useState<boolean>(false);
  const firebase = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      firebase.ProductService.updateRawMaterialName(id, docId, name);
    }
  };

  const onProductPartDelete = () => {
    firebase.ProductService.deleteProductPart(id, docId);
    callback();
  };

  return (
    <TitleContainer>
      <TitleText value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(event) => handleKeyDown(event)} />
      <ContainerButtons>
        <AddButton onClick={() => setIsRawMaterialVisible(true)}>Add Base Material</AddButton>
        <AddButton onClick={() => setIsPlatingVisible(true)}>Add Plating</AddButton>
        <AddRawMaterial
          callback={callback}
          docId={docId}
          data={data}
          layerName={LayerName}
          onClose={() => setIsRawMaterialVisible(false)}
          isVisible={isRawMaterialVisible}
        />
        <AddPlating
          callback={callback}
          docId={docId}
          data={data}
          layerName={LayerName}
          onClose={() => setIsPlatingVisible(false)}
          isVisible={isPlatingVisible}
        />
        <DeleteTableButton onClick={() => setDeleteProductPartModalVisible(true)} />
        <DeleteModal
          onClose={() => setDeleteProductPartModalVisible(false)}
          isVisible={isDeleteProductPartModalVisible}
          title="Delete product part"
          onDelete={onProductPartDelete}
        />
      </ContainerButtons>
    </TitleContainer>
  );
}

const TitleContainer = styled.div`
  width: 100%;
  height: 70px;
  flex-direction: row;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const TitleText = styled.input`
  font-size: 18px;
  border: none;

  &:focus {
    outline: none;
  }
`;
