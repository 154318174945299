import { useFirebaseContext } from "core/contexts/FirebaseContext";
import React, { useState } from "react";
import styled from "styled-components";
import { MaterialType } from "type";
import ProductPartListElement from "./ProductPartListElement";
import SearchField, { ProductBase } from "../../../../forms/SearchField";
import { useParams } from "react-router";
import { Modal } from "components/common/modals/Modal";
import { uuidv4 } from "core/utilities/uuid";
import { MaterialModel } from "services/domain/products/models/ProductModel";

interface Props {
  layerName: string;
  data: MaterialModel[];
  docId: string;
  callback: () => void;
  isVisible: boolean;
  onClose: () => void;
  productType: MaterialType;
  productList: ProductBase[]
}

export function AddProductPartModal({
  layerName,
  data,
  docId,
  callback,
  onClose,
  isVisible,
  productType,
  productList
}: Props): JSX.Element {
    const [state, setstate] = useState<MaterialModel>();
    const firebase = useFirebaseContext();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { id } = useParams<{ id: string }>();

  const results = !searchTerm ?
  productList : 
  productList.filter(product => product.Name.toLowerCase().includes(searchTerm.toLowerCase()))

  const handleSubmit = () => {
    firebase?.ProductService.updateMaterials(id, docId, [...data, state]);
    isVisible=false;
    callback();
  };

  const handleClick = (name: string, price: string) => {
    const item: MaterialModel = {
      basePrice: price,
      category: "",
      layerName: layerName,
      totalPrice: price,
      type: productType,
      volume: productType === MaterialType.PLATING ? "1" : "",
      weight: productType === MaterialType.BASE_MATERIAL ? "1" : "",
      name: name,
      id: uuidv4()
    };
    setstate(item);
  };

  return (
    <>
     {isVisible && (
    <Modal
      show={isVisible}
      onHide={() => onClose()}
      title={`Add ${productType}`}
      handleSubmit={handleSubmit}
      noFooter={false}
    >
       <SearchField
        handleChange={setSearchTerm}
      />
    <>
     
      <ContainerList>
        {results.sort((a,b)=>a.Name > b.Name ? 1 : -1).map((value, index) => {
          return <ProductPartListElement handleClick={handleClick} key={index} {...value} />;
        })}
      </ContainerList>
      </>
     </Modal>)}
     </>
  );
}
const ContainerList = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
max-height: 400px;
overflow-y: auto;
`;
