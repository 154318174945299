import { loginSchema } from "components/auth/loginSchema";
import { useUserContext } from "core/contexts/UserContext";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/elements/buttons/Button";
import { FormInputContainer } from "components/common/FormInputContainer";

interface LoginForm {
  email: string;
  password: string;
}

export function LoginPage(): JSX.Element {
  const { loginWithEmail } = useUserContext();
  const { register, handleSubmit, getValues, errors } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
  });

  const doLogin = (values: LoginForm) =>
    loginWithEmail(values.email, values.password);

  return (
    <Container>
      <ContainerLeft />
      <ContainerLogin onSubmit={handleSubmit(doLogin)}>
        <FormInputContainer
          register={register}
          value={getValues().email}
          type="email"
          placeholder="John.doe@domain.com"
          label="Email"
          error={errors.email?.message}
          name="email"
        />
        <FormInputContainer
          register={register}
          type="password"
          value={getValues().password}
          placeholder="Your password"
          label="Password"
          name="password"
          error={errors.password?.message}
        />
        <Button type="submit">Log in</Button>
      </ContainerLogin>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContainerLeft = styled.div`
  width: 60%;
  height: 100vh;
`;

const ContainerLogin = styled.form`
  width: 40%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.mainColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
