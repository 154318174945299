import styled, { css } from "styled-components";

export const StyledCategorySelectorContainer = styled.div`
  height: auto;
  width: 80%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

export const StyledCategoryItem = styled.div<{ isEmpty: boolean }>`
  background-color: ${(props) => props.theme.colors.lightColor};
  flex: 1;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${(props) =>
    props.isEmpty &&
    css`
      max-width: calc((100% / 4) - 15px);
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.mainColor};
    cursor: pointer;
  }
`;
