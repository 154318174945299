declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_apiKey: string;
      REACT_APP_authDomain: string;
      REACT_APP_databaseURL: string;
      REACT_APP_projectId: string;
      REACT_APP_storageBucket: string;
      REACT_APP_messagingSenderId: string;
      REACT_APP_appId: string;
      REACT_APP_measurementId: string;
      REACT_APP_COMMOPRICE: string;
    }
  }
}
export interface CSVObject {
  Area: string;
  Category: string;
  Color: string;
  Count: string;
  Curve: string;
  ID: string;
  LayerName: string;
  Material: string;
  Name: string;
  Perimeter: string;
  Type: string;
  Volume: string;
}


export interface COGS {
  packaging: string;
  materials: string;
  labor: string;
}

export interface PiecePriceBreakdown {
  packaging: string;
  materials: {
    baseMaterialTotal: number;
    platingTotal: number;
    miscTotal: number
  };
  laborTotal: number;
}

export interface ILabor {
  name: string;
  duration: string;
  basePrice: string;
  totalPrice: string;
  id: string;
}

export interface MarketPriceDataModel {
  wholesalePrice: number;
  retailPrice: number;
  useDefaultCalculation: boolean;
}


export interface MaterialCreation {
  name: string;
  price: string;
}

export interface MaterialWrapper {
  materials: Material[];
}

export interface MaterialFirestore {
  name: string;
  id: string;
  materials: Material[];
  createdAt: Date;
}

export enum MaterialType {
  PLATING = "Plating",
  BASE_MATERIAL = "Base Material"
}

export interface Material {
  Name: string;
  Weight: string;
  BasePrice: string;
  TotalPrice: string;
  Type: MaterialType;
  Volume: string;
  Category: string;
  LayerName: string;
  ID: string;
  Area?: string;
  Density?: string;
}
export interface MaterialAPI {
  Name: string;
  Price: string;
  Date: string;
  Currency: string;
  Density?: string;
}

export interface Header {
  name: string;
  unit: string | null;
  size: string;
  align: string;
  key?: string;
  order: number;
  transform?(value: any): string | number;
}

export interface FirebaseDate {
  seconds: number;
  nano: number;
}

export interface Plating {
  name: string;
  price: string;
}
interface Metadata {
  createdAt?: Date | FirebaseDate;
  updatedAt?: Date | FirebaseDate;
}

export interface MiscCollection {
  name: string;
  id: string;
  materials: Misc[];
  createdAt: Date;
}

export interface Misc {
  name: string;
  quantity: string;
  totalPrice: number
  unitPrice: number,
  id: string
}

export interface StaticMisc {
  name: string;
  price: string;
  weight: number;
  id?: string;
}
