import { LogoV1 } from "styles/assets";
import { Fade } from "components/common/Fade";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface LoadingProcess {
  name: string;
  isReady: boolean;
}

interface Props {
  /**
   * A collection of process that should be ready before displaying the application   *
   */
  mandatoryProcesses?: LoadingProcess[];
  /**
   * Fake loading time
   */
  minimumLoadingTime: number;
  /**
   * For log only
   */
  id?: number;
}

/**
 * A component used to display a loading while some processes are loading.
 */
export const AppLoader: React.FC<Props> = React.memo((props) => {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState<boolean>(
    (props.minimumLoadingTime || 0) <= 0
  );

  // Handle potential minimum duration
  useEffect(() => {
    if (props.minimumLoadingTime) {
      setTimeout(
        () => setMinimumDurationPassed(true),
        props.minimumLoadingTime
      );
    }
  }, []);

  const isReady =
    props.mandatoryProcesses?.every(
      (mandatory) => mandatory.isReady === true
    ) && minimumDurationPassed;

  return (
    <React.Fragment>
      {isReady ? props.children : null}
      <Fade show={!isReady}>
        <LoadingComponent />
      </Fade>
    </React.Fragment>
  );
});

const LoadingBackground = styled.div`
  background-color: ${(props) => props.theme.colors.mainColor};
  color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 46px;
  z-index: 10000;
`;

export const LoadingComponent: React.FC = () => (
  <LoadingBackground>
    <Image src={LogoV1} />
  </LoadingBackground>
);

const Image = styled.img`
  max-width: 1200px;
  height: auto;
`;
