import { MaterialType } from "type";
import React, { useEffect, useRef } from "react";
import { ProductPartsViewHeader } from "./ProductPartsViewHeader";
import { StyledProductPartsView } from "./ProductPartsView.styles";
import { Row } from 'react-table'
import { TableComponent } from "../tableMaterial/TableComponent";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { MaterialModel } from "services/domain/products/models/ProductModel";

interface Props {
  title: string;
  data: MaterialModel[];
  docId: string;
  productId: string;
  callback: () => void;
}

interface ProductPartTableData {
  name: string
  type: string;
  weight: string;
  volume: string;
  basePrice: string;
  totalPrice: string;
  id: string
}


export function ProductPartsView({
  title,
  data,
  docId,
  productId,
  callback
}: Props): JSX.Element {

  const firebase = useFirebaseContext();
  const stateRef = useRef<MaterialModel[]>();

  useEffect(() => {
    stateRef.current = data;
  }, [data])

  const handleChange = (index: number, id: string, value: string) => {
    if(isNaN(parseFloat(value))) {
      console.log(`${value} is not a valid number`);
      return false;
    } 
    stateRef.current[index][id] = value;
    firebase.ProductService.updateMaterials(productId, docId,  stateRef.current)
    return true;
  }

 
  const dataTable  = React.useMemo<ProductPartTableData[]>(
    () => data.map(d => ({
      name: d.name,
      type: d.type === MaterialType.BASE_MATERIAL ? 'Base Material' : 'Plating',
      weight: d.weight ? parseFloat(d.weight).toFixed(2) : 'NA',
      volume: d.volume ? parseFloat(d.volume).toFixed(2) : 'NA',
      basePrice: parseFloat(d.basePrice).toFixed(2),
      totalPrice: parseFloat(d.totalPrice).toFixed(2),
      id: d.id
    })),
    [data]
  )


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        
        accessor: 'name', 
        style:{
          width: '10%',
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        style:{
          width: '60%',
        }
      },
      {
        Header: 'Surface',
        subHeader: 'mm2',
        accessor: 'volume',
        isEditable: true,
        style:{
          width: '7.5%',
          'text-align': 'right'
        }
      },
      {
        Header: 'Weight',
        subHeader: 'gram',
        accessor: 'weight',
        isEditable: true,
        style:{
          width: '7.5%',
          'text-align': 'right'
        }
      },
      {
        Header: 'Base Price',
        subHeader: '$/g',
        accessor: 'basePrice',
        style:{
          width: '7.5%',
          'text-align': 'right'
        }
      },
      {
        Header: 'Total Price',
        subHeader: '$',
        accessor: 'totalPrice',
        style:{
          width: '7.5%',
          'text-align': 'right'
        }
      },
      {
        Header: 'ID',
        accessor: 'id'
      },
    ],
    []
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDelete = (row: Row<any>) => {
    const updatedData = data.filter(d=> d.id != row.values.id);
    firebase.ProductService.updateMaterials(productId, docId, updatedData)
  }

  return (
    <StyledProductPartsView>
      <ProductPartsViewHeader callback={callback} docId={docId} data={data} LayerName={title}>
        {title ? title : "Add New Product Part"}
      </ProductPartsViewHeader>
      {data.length > 0 && 
      <TableComponent 
        updateMyData={handleChange}
        data={dataTable}
        hasItemOptions={true}
        columns={columns} 
        onDelete={onDelete}/>
      }
    </StyledProductPartsView>
  );
}
