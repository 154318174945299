import { useEffect, useState } from 'react';

import { companyName } from '../../../../services/external/firebase/FirebaseUtility';
import { useAppContext } from 'core/contexts/AppContext';
import { ProductFirestore } from 'services/external/firebase/models/ProductFirestore';
import { MapProductToDomainModel } from 'services/external/firebase/models/mapper/ProductFirestoreMapper';
import { JewelryProductModel } from 'services/domain/products/models/specifc/JewelryProductModel';

interface UseGetProductByIdSnapshotInterface {
  product: JewelryProductModel;
}

export function useGetProductByIdSnapshot(id: string): UseGetProductByIdSnapshotInterface {
  const { firebase } = useAppContext();
  const [product, setProduct] = useState<JewelryProductModel>();

  useEffect(() => {
    const unsubscribe = firebase!
      .firestore()
      .collection('clients')
      .doc(companyName)
      .collection('Products')
      .doc(id)
      .onSnapshot(function (querySnapshot) {
        const data = querySnapshot.data() as ProductFirestore;
        if (!!data) {
          setProduct(MapProductToDomainModel(data));
        }
        console.log('call to get product');
      });
    return () => unsubscribe();
  }, []);

  return { product };
}
