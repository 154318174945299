import { Container } from "components/elements/Container";
import { ErrorLabel } from "components/elements/ErrorLabel";
import { Input } from "components/elements/Input";
import { Label } from "components/elements/Label";
import React from "react";
import { UseFormMethods } from "react-hook-form";

interface InputContainerProps extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  error?: string;
  label: string;
  placeholder: string;
  type: string;
  value?: string;
  name: string;
  disabled?: boolean;
  black?: boolean;
  highlightValue?: boolean;
  onInputChange?: (val: string) => void;
  pattern?: string;
}

export const FormInputContainer: React.FC<InputContainerProps> = ({ 
  errors = {},
  error,
  register,
  label,
  placeholder,
  type,
  value,
  name,
  disabled,
  black,
  highlightValue,
  pattern}) => {
    const currentError = errors[name] && errors[name].message;

    return ( 
  <Container>
    <Label black={black}>{label}</Label>
    <Input
      pattern={pattern}
      defaultValue={value}
      error={!!currentError || !!error}
      ref={register}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      name={name}
      black={black}
      highlightValue={highlightValue}
    />
    {error && (
        <ErrorLabel>{error}</ErrorLabel>
      )}
     {errors[name] && (
        <ErrorLabel>{currentError}</ErrorLabel>
      )}
  </Container>
)};
