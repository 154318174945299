import React from 'react';

import styled from 'styled-components';
import { ProductVendorsModel } from 'services/domain/products/models/ProductModel';
import { ProductVendorsComponent } from './ProductVendorsComponent';
import { AddProductVendorsModal } from './AddProductVendorsModal';
import { useParams } from 'react-router';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';

interface Prop {
  productVendors: ProductVendorsModel[];
}

export const ProductVendorsView = ({ productVendors }: Prop): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const firebase = useFirebaseContext();
  const handlePreferedVendorChange = (vendorId: string) => {
    if (vendorId) {
      firebase.ProductService.updatePreferedVendor(id, vendorId);
    } else {
      firebase.ProductService.removedPreferedVendor(id);
    }
  };

  return (
    <Container>
      <AddProductVendorsModal productId={id} />
      {!!productVendors &&
        productVendors.map((productVendor) => (
          <ProductVendorsComponent key={productVendor.id} productVendor={productVendor} productId={id} onPreferedVendorClick={handlePreferedVendorChange} />
        ))}
    </Container>
  );
};

const Container = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  justify-content: left;
  > * {
    margin: 0 10px;
  }
`;
