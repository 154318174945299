import {
  Dropdown, OnChangeData,
} from "carbon-components-react";
import { useState } from "react";

import { Add20 } from "@carbon/icons-react";
import { ModalWrapper } from "../../../../components/common/modals/ModalWrapper";
import { Routes } from "AppRouter";
import { TextInput } from "../../../../components/common/modals/TextInput";
import styled from "styled-components";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { useHistory } from "react-router";
import { VendorModel } from "services/external/firebase/models/VendorsModel";
import { countryList } from "core/data/countries";

export function AddVendorsModal(): JSX.Element {
  const history = useHistory();
  const firebase = useFirebaseContext();
  const [vendor, setVendor] = useState<VendorModel>({
    id:"",
    name: "",
    country: "",
  });

  const handleSubmit = async () => {
    const res = await firebase.VendorService.createVendor(vendor);
    history.push(Routes.VENDOR.replace(":id", res.id));
  };

  const handleCountryChange = (param: string | null | undefined) => {
    if (param) {
      const index = countryList.findIndex((element) => element === param);
      setVendor({ ...vendor, country: countryList[index] });
    }
  };

  const button = (
    <ContainerHeaderTitle>
      <Add20 />
      <p>Add Vendor</p>
    </ContainerHeaderTitle>
  );

  return (
    <ModalWrapper
      handleSubmit={handleSubmit}
      button={button}
      title="Add Vendor"
    >
      <TextInput
        label="Vendor name"
        handleChange={(e: string) => setVendor({ ...vendor, name: e })}
        defaultValue={vendor.name}
      />
        <StyledDropdownContainer>
          <StyledDropdown
                    id="category"
                    onChange={(data: OnChangeData) => handleCountryChange(data.selectedItem)}
                    items={countryList}
                    label="France"
                    defaultValue={countryList[0]}
                    titleText="Country"
                  />
        </StyledDropdownContainer>
       
      )
    </ModalWrapper>
  );
}

const StyledDropdownContainer = styled.div `
  margin-bottom:10px;
  `

const StyledDropdown = styled(Dropdown) `
  position: absolute;
  width: 90%;
  `

const ContainerHeaderTitle = styled.div`
  width: 140px;
  height: 40px;
  border: 1px solid unabacusblack;
  text-align: center;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #efefef;

  p {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
  }
`;
