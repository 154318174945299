import React, { useState } from "react";

import styled from "styled-components";

interface Props {
  Name: string;
  Price: string;
  handleClick: (name: string, price: string) => void;
}

export default function ProductPartListElement({ Name, Price, handleClick }: Props): JSX.Element {
  const [state, setstate] = useState<boolean>(false);

  const Click = () => {
    handleClick(Name, Price);
    setstate(!state);
  };

  return (
    <Container selected={state} onClick={Click}>
      <ItemName>{Name}</ItemName>
      <ItemPrice>{parseFloat(Price).toFixed(2) + " €/g"}</ItemPrice>
    </Container>
  );
}

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.borderLightgrey};

  ${(props) =>
    !props.selected
      ? `        
        background-color: #fff
    `
      : `
        background-color: ${props.theme.colors.backgroundGreyLight};
    `};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundGreyLight};
    cursor: pointer;
  }
`;

const ItemName = styled.p`
  color: ${(props) => props.theme.colors.unabacusblack};
  font-weight: 700;
  font-size: 13px;
`;

const ItemPrice = styled.p`
  color: ${(props) => props.theme.colors.lightGray};
  font-size: 13px;
`;
