import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title: string | ReactNode;
  rightComponent: ReactNode;
}

export function HeaderLayout(props: Props): JSX.Element {
  return (
    <StyledTitleLayout>
      <div>{props.title}</div>
      <ContainerButtons>{props.rightComponent}</ContainerButtons>
    </StyledTitleLayout>
  );
}

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTitleLayout = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: #fff;
  font-size: 23px;
  font-weight: 700;
  width: 100%;
  justify-content: space-between;
`;

