import styled from "styled-components";

interface Props {
  variant?: "primary" | "secondary" | "third";
  primary?;
  secondary?;

}

export const Button = styled.button<Props>`
  height: 40px;
  background-color: ${props => props.variant === 'primary' ? props.theme.colors.mainColor : props.variant === 'secondary' ? 'white' : 'black'};
  color:  ${props => props.variant === 'primary' ? props.theme.colors.white : props.variant === 'secondary' ?  props.theme.colors.mainColor : 'black'};
  border: ${props => props.variant === 'primary' ? 'none' : props.variant === 'secondary' ? `solid ${props.theme.colors.mainColor} 1px` : 'none'};
  font-size: 13px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.variant === 'primary' ? props.theme.colors.mainColor : props.variant === 'secondary' ? props.theme.colors.mainColor : 'black'};
    color:  ${props => props.variant === 'primary' ? props.theme.colors.white : props.variant === 'secondary' ?  props.theme.colors.white : 'black'};
  }
`;

Button.defaultProps = {
  variant: "primary",
};
