import React, { useEffect, useState } from "react";

import { HeaderLayout } from "components/common/HeaderLayout";
import { Layout } from "components/common/Layout";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { CategorySelector } from "modules/category/components/CategorySelector";
import AddCategoryModal from "modules/category/components/addCategory";
import { AddProductModal } from "modules/product/components/forms/addProduct";
import { ContainerFlexColumn } from "components/elements/ContainerFlexColumn";
import { CategoryFirestore } from "services/external/firebase/models/CategoryFirestore";
import styled from "styled-components";
import {
  ContainerSection,
  SectionHeader,
} from "components/common/table/styled";
import { NewProductTable } from "modules/category/components/products/NewProductTable";

function App(): JSX.Element {
  const firebase = useFirebaseContext();
  const [categories, setCategories] = useState<CategoryFirestore[]>([]);

  useEffect(() => {
    getCategoriesFromFirebase();
  }, []);

  const getCategoriesFromFirebase = async () => {
    const fetchedCategories = await firebase.getCategories();

    setCategories(fetchedCategories);
  };

  return (
    <Layout>
      <ContainerFlexColumn>
        <HeaderLayout
          title="Catalog"
          rightComponent={
            <>
              <AddCategoryModal title="Add Category" />
              <AddProductModal />
            </>
          }
        />
        <ContainerTableProducts>
          <ContainerSection>
            <SectionHeader>
              <ProductCount>
                <ProductCountText>0</ProductCountText>
              </ProductCount>
              All products
            </SectionHeader>
          </ContainerSection>
          {
            <NewProductTable
              queryData={(param) => firebase.searchProduct(param)}
            />
          }
        </ContainerTableProducts>
        <CategorySelector categories={categories} />
      </ContainerFlexColumn>
    </Layout>
  );
}

const ContainerTableProducts = styled.div`
  padding: 2rem;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
`;

const ProductCount = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
`;

const ProductCountText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;

export default App;
