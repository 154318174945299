import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { matchSorter } from 'match-sorter';
import { Button } from 'components/elements/buttons/Button';
import { Column, Row, useFilters, useTable } from 'react-table';

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      className="searchInput"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={``}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

interface Props<T extends Record<string, any>> {
  data: T[];
  columns: Column<T>[];
  hasItemOptions: boolean;
  showMore?: {
    enabled: boolean;
    increment: number;
    onClick: (index: number) => void;
  };
  onRowClick?: (row: T) => void;
  onDelete?: (row: Row<T>) => void;
  updateMyData?: (index: number, id: string, value: string) => boolean;
}

export function TableComponent<T extends Record<string, any>>({
  data,
  columns,
  hasItemOptions = true,
  showMore = {
    enabled: false,
    increment: 0,
    onClick: () => null,
  },
  onRowClick,
  onDelete,
  updateMyData,
}: Props<T>): JSX.Element {
  //////// START EDIT ///////
  const EditableCell = ({ value: initialValue, row: { index }, column: { id, isEditable } }: any) => {
    const [value, setValue] = React.useState<string>(initialValue);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const applyChanges = () => {
      const isValid = updateMyData(index, id, value);
      if (!isValid) {
        setValue(initialValue);
      }
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return isEditable && initialValue != 'NA' ? (
      <input className="edit-input" value={value} onChange={onChange} onKeyPress={(e) => e.key === 'Enter' && applyChanges()} onBlur={applyChanges} />
    ) : (
      <>{value}</>
    );
  };

  //////// END EDIT ///////

  //////// START FILTERING //////

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      },
    }),
    []
  );

  //////// START SHOW MORE //////

  const [currentPaginationIndex, setCurrentPaginationIndex] = useState<number>(1);

  const onShowMoreClick = () => {
    setCurrentPaginationIndex(currentPaginationIndex + showMore.increment);
    showMore.onClick(currentPaginationIndex);
  };

  //////// END SHOW MORE //////

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
    Filter: DefaultColumnFilter,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    state: { filters },
    prepareRow,
  } = useTable<T>({ columns, data, defaultColumn, manualFilters: false, initialState: { hiddenColumns: ['id'] } }, useFilters);

  //////// END FILTERING //////

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div style={style}>
          <tr
            {...row.getRowProps([
              {
                className: !!onRowClick ? 'is-clickable' : '',
              },
            ])}
            onClick={() => (!!onRowClick ? onRowClick(row.original) : null)}
          >
            {row.cells.map((cell: any) => {
              return (
                <td
                  {...cell.getCellProps([
                    {
                      style: cell.column.style,
                    },
                  ])}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
            {hasItemOptions && (
              <OverflowMenu className="toHover">
                <OverflowMenuItem onClick={() => onDelete(row)} itemText="Delete" isDelete />
              </OverflowMenu>
            )}
          </tr>
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <StyledTableComponent scrollbarWidth={getScrollbarWidth()}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <>
                  <th
                    key={column.id}
                    {...column.getHeaderProps([
                      {
                        style: column.style,
                      },
                    ])}
                  >
                    {column.render('Header')}
                    <div className="searchInput">{column.filterEnabled ? column.render('Filter') : null}</div>
                    <div className="sub">{column.subHeader}</div>
                  </th>
                </>
              ))}
              {hasItemOptions && <th style={{ width: '2.5rem' }}></th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tbody">
          {/* <FixedSizeList 
         className="fixedList"
         height={400}
         itemCount={rows.length}
         itemSize={35}
         >
           {RenderRow} */}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <tr
                  key={row.id}
                  {...row.getRowProps([
                    {
                      className: !!onRowClick ? 'is-clickable' : '',
                    },
                  ])}
                  onClick={() => (!!onRowClick ? onRowClick(row.original) : null)}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={cell.id}
                        {...cell.getCellProps([
                          {
                            style: cell.column.style,
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                  {hasItemOptions && (
                    <OverflowMenu className="toHover">
                      <OverflowMenuItem onClick={() => onDelete(row)} itemText="Delete" isDelete />
                    </OverflowMenu>
                  )}
                </tr>
              </>
            );
          })}
          {/* </FixedSizeList> */}
        </tbody>
      </table>
      {showMore?.enabled && (
        <Button className="show-more" primary onClick={() => onShowMoreClick()}>
          Show more
        </Button>
      )}
    </StyledTableComponent>
  );
}

interface StyledTableProp {
  scrollbarWidth: number;
}

const StyledTableComponent = styled.div<StyledTableProp>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .show-more {
    width: auto;
  }

  input {
    width: 5rem;
    text-align: right;
    border: 1px solid lightgray;
  }
  .searchInput {
    padding-top: 5px;
    width: 100%;
    text-align: left;
  }

  .fixedList {
    width: calc(100% + ${(props) => props.scrollbarWidth}px);
  }

  tr > th:first-of-type,
  tr > td:first-of-type {
    padding-left: 15px;
    padding-right: 5px;
  }

  th,
  td {
    padding-right: 15px;
  }

  th {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    color: black;
    font-size: 13px;
    vertical-align: middle;
    box-sizing: border-box;

    .sub {
      color: #c0c0c0;
      font-size: 11px;
      padding: 2px 0;
    }
  }

  .tbody {
    .toHover svg {
      display: none;
    }
    .toHover:hover svg {
      display: block;
    }

    .bx--overflow-menu--open svg {
      display: block;
    }

    .is-clickable:hover {
      background-color: #cccccc;
      cursor: pointer;
      border-top: 0.4px solid #cccccc;
    }

    > tr {
      border-top: 0.4px solid #e2e2e2;

      :nth-child(odd) {
        background: #ffffff;
      }

      :nth-child(even) {
        background: #fbfbfb;
      }

      td,
      svg {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: inherit;
        vertical-align: middle;
      }
      svg {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .thead {
    background-color: #fff;
    width: 100%;
    height: auto;
    border-top: 0.4px solid #e2e2e2;
    > tr {
      width: 100%;
      border-top: 0.4px solid #e2e2e2;
    }
  }
`;

// const ContainerHeader = styled.div<{ size: string }>`
//   color: grey;
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: column;

//   ${(props) => {
//     switch (props.size) {
//       case "sm":
//         return css`
//           width: 10%;
//         `;
//       case "nm":
//         return css`
//           width: 20%;
//         `;
//       case "lg":
//         return css`
//           width: 60%;
//         `;
//     }
//   }}
// `;
