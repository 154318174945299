import styled from 'styled-components';

export const HeaderButton = styled.button`
  width: 140px;
  height: 40px;
  border: 1px solid #353535;
  text-align: center;
  line-height: normal;

  &:hover {
    cursor: pointer;
  }
`;
