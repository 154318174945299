import { SideNav, SideNavItems, SideNavLink } from "carbon-components-react";
import { Routes } from "AppRouter";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  AudioConsole16,
  Camera16,
  Catalog16,
  ChartColumn16,
  ChartLine16,
  Chemistry16,
  ToolBox16,
} from "@carbon/icons-react";

export function NavigationMenu(): JSX.Element {
  return (
    <React.Fragment>
      <Sidenav
        isFixedNav
        expanded={true}
        className="sidenav"
        aria-label="miansai-sidebar"
      >
        <SideNavItems>
          <LinkStyled activeClassName="link-active" to={Routes.CATALOG}>
            <StyledSideNavLink renderIcon={Catalog16}>
              Catalog
            </StyledSideNavLink>
          </LinkStyled>
          <LinkStyled activeClassName="link-active" to={Routes.VENDORLIST}>
            <StyledSideNavLink renderIcon={AudioConsole16}>
              Vendors
            </StyledSideNavLink>
          </LinkStyled>
          <Divider>Economic</Divider>
          <StyledSideNavLink renderIcon={ChartColumn16}>
            Breakdown
          </StyledSideNavLink>
          <StyledSideNavLink renderIcon={Camera16}>Section C</StyledSideNavLink>
          <StyledSideNavLink renderIcon={ChartLine16}>
            Section D
          </StyledSideNavLink>
          <Divider>Divider B</Divider>
          <StyledSideNavLink renderIcon={Chemistry16}>
            Section E
          </StyledSideNavLink>
          <StyledSideNavLink renderIcon={ToolBox16}>
            Section F
          </StyledSideNavLink>
          <StyledSideNavLink renderIcon={ToolBox16}>
            Section G
          </StyledSideNavLink>
        </SideNavItems>
      </Sidenav>
    </React.Fragment>
  );
}

const Divider = styled.p`
  color: #8c85a1;
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 0.5rem;
  padding-top: 1rem;
`;

const StyledSideNavLink = styled(SideNavLink)`
  .bx--side-nav__link-text {
    font-weight: 400 !important;
    font-size: 12px !important;
  }

  .bx--side-nav__icon {
    margin-right: 0.5rem;
    > svg {
      fill: white;
    }
  }
`;
export const Sidenav = styled(SideNav)`
  background-color: ${(props) => props.theme.colors.mainColor};
  width: 140px !important;
  color: #fff !important;
  font-size: 10px;

  .bx--side-nav__item {
    padding: 0.25rem 0.5rem;
  }

  .link-active > .bx--side-nav__item:nth-of-type(1) > .bx--side-nav__link {
    background-color: #8253e2 !important;
  }

  .bx--side-nav__link:hover,
  .bx--side-nav__link:active {
    background-color: #8253e2 !important;
  }
`;

export const CustomSideNav = styled(SideNavLink)`
  color: #fff !important;
`;

export const LinkStyled = styled(NavLink)`
  color: red;
  text-decoration: none;
  .link-active > .bx--side-nav__item {
    background-color: #8253e2;
  }
`;
