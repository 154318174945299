import { PiecePriceBreakdown } from "type";
import React from "react";
import styled from "styled-components";

interface Props {
  details: PiecePriceBreakdown;
}

export function CalculatorDetails({ details }: Props): JSX.Element {
  return (
    <Container>
      <Hint>Base Material</Hint>
      <Price>{details.materials.baseMaterialTotal.toFixed(2)}$</Price>
      <Hint>Misc</Hint>
      <Price>{details.materials.miscTotal.toFixed(2)}$</Price>
      <Hint>Plating</Hint>
      <Price>{details.materials.platingTotal.toFixed(2)}$</Price>
      <Hint>Labor Cost</Hint>
      <Price>{details.laborTotal.toFixed(2)}$</Price>
    </Container>
  );
}

const Container = styled.div`
  height: auto;
  width: 140px;
  border-top-left-radius: 8px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
  position: absolute;
  bottom: 60px;
  right: 0;
  padding: 20px;
  text-align: right;
`;

const Hint = styled.p`
  font-size: 11px;
  color: white;
`;

const Price = styled.p`
  font-weight: 700;
`;
