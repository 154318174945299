import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  placeholder: string;
  onSubmit: (partName: string) => void;
}

export function AddRawMaterialPart({ 
  placeholder,
  onSubmit
 }: Props): JSX.Element {
  const [state, setstate] = useState<string>("");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit(state);
      setstate("");
    }
  };

  return (
    <Container>
      <Input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setstate(e.target.value) }
        value={state}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  background-color: white;
  border: none;
  font-size: 20px;

  &:focus {
    border: none;
    outline: none;
  }
`;
