import { Routes } from "AppRouter";
import { AuthStatus } from "core/hooks/user/useUserHooks";
import { useUserContext } from "core/contexts/UserContext";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

const UnavailableAuthRoutes = [AuthStatus.AUTH];

export const NotAuthRoute: React.FC<RouteProps> = ({
  component: RouteComponent,
  ...rest
}) => {
  const { authStatus } = useUserContext();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !UnavailableAuthRoutes.includes(authStatus) ? (
          // @ts-ignore
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={Routes.HOME} />
        )
      }
    />
  );
};
