import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { Layout } from "components/common/Layout";
import { HeaderLayout } from "components/common/HeaderLayout";
import React, { useEffect, useState } from "react";
import { VendorModel } from "services/external/firebase/models/VendorsModel";
import { AddVendorsModal } from "modules/vendors/components/modals/addVendorsModal";
import { VendorsTable } from "modules/vendors/components/table/VendorsTable";
import styled from "styled-components";

export function VendorListPage(): JSX.Element {
  const [vendors, setVendors] = useState<VendorModel[]>([]);

  const firebase = useFirebaseContext();

  useEffect(() => {
    const fetchVendors = async () => {
      const fetchedData = await firebase.VendorService.getAllVendors();
      setVendors(fetchedData);
    };
    fetchVendors();
  }, []);

  return (
    <Layout>
      <HeaderLayout title="Vendors" rightComponent={<AddVendorsModal />} />
      {!!vendors && (
        <ContentLayout>
          <VendorsTable vendorList={vendors} />
        </ContentLayout>
      )}
    </Layout>
  );
}

const ContentLayout = styled.div`
  padding: 0 1rem;
  width: 100%;
`;
