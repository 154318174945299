import firebase from "firebase/app";
import "firebase/firestore";
import { VendorModel } from "./models/VendorsModel";

export const companyName = "TeAskuaU9OMojQv6YELZ";

enum CollectionsFirestore {
  VENDORS = "Vendors",
}

export class FirebaseVendorService {
  private FIRESTORE: firebase.firestore.Firestore;
  private VENDORS!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firebaseCtx: firebase.app.App) {
    this.FIRESTORE = firebaseCtx.firestore();
    this.initFirebaseCollection();
  }

  /**
   * Init collections variables
   */
  initFirebaseCollection(): void {
    this.VENDORS = this.FIRESTORE.collection("clients")
      .doc(companyName)
      .collection(CollectionsFirestore.VENDORS);
  }

  createVendor(
    vendor: VendorModel
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    return this.VENDORS.add({
      ...vendor,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  async getProductById(id: string): Promise<VendorModel> {
    const fetchedProduct = await this.VENDORS.doc(id).get();
    return fetchedProduct.data() as VendorModel;
  }

  deleteVendor(id: string): Promise<void> {
    return this.VENDORS.doc(id).delete();
  }

  updateVendor(id: string, vendor: VendorModel): Promise<void>{
    return this.VENDORS.doc(id).update({ ...vendor });
  }

  async getAllVendors(): Promise<VendorModel[]> {
    const vendors: VendorModel[] = [];
    await this.VENDORS
      .orderBy('createdAt')
      .get()
      .then(async (results) => {
        results.forEach((doc) => {
          vendors.push({ ...doc.data() as VendorModel, id: doc.id} as VendorModel);
        })
      });
      return vendors;
  }
}