import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { Modal } from "./Modal";
import styled from "styled-components";

interface Props {
  button?: ReactNode;
  handleSubmit: () => void;
  title: string | null;
  noFooter?: boolean;
  parentOpen?: boolean;
}

export const ModalWrapper: React.FC<Props> = ({
  children,
  button,
  handleSubmit,
  title,
  noFooter,
  parentOpen,
}) => {
  const [state, setState] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    setState(true);
  }, []);

  useEffect(() => {
    if (!parentOpen) {
      setState(false);
    } else {
      setState(true);
    }
  }, [parentOpen]);

  const onSubmit = () => {
    setState(false);
    handleSubmit();
  };

  return (
    <>
      <span onClick={handleClick}>{button}</span>
      {state && (
        <Container>
          <Modal
            title={title}
            handleSubmit={onSubmit}
            noFooter={noFooter}
            onHide={() => setState(false)}
            show={state}
          >
            {children}
          </Modal>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
