import { CSVImportComponent } from './csv/ProductCsvImportComponent';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeleteModal } from './rawMaterial/components/tableMaterial/DeleteModal';
import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useHistory, useParams } from 'react-router';
import { TrashCan20 } from '@carbon/icons-react';
import { Routes } from 'AppRouter';
import { HeaderButton } from 'components/common/buttons/HeaderButton';
import { SpecificationExport } from 'services/pdf/ExportPdf';
import { BlobProvider } from '@react-pdf/renderer';
import { useGetProductByIdSnapshot } from 'core/hooks/firebase/products/useGetProductById';
import firebase from 'firebase';

export default function ProductHeader(): JSX.Element {
  const [isDeleteMiscModalVisible, setDeleteMiscModalVisible] = useState<boolean>(false);
  const [specificationImageLink, setSpecificationImageLink] = useState<string>(undefined);
  const firebaseCtx = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const { product } = useGetProductByIdSnapshot(id);

  const history = useHistory();

  useEffect(() => {
    const func = async () => {
      if (product?.specification?.imagePath) {
        const imageUrl = await downloadImage(product.specification.imagePath);
        setSpecificationImageLink(imageUrl);
      }
    };
    func();
  }, [product]);
  const onProductDelete = () => {
    firebaseCtx.ProductService.deleteProduct(id).then(() => history.push(Routes.HOME));
  };

  const downloadImage = async (imageUri: string): Promise<string> => {
    const storage = firebase.storage();
    const pathReference = storage.ref(imageUri);
    const downloadUri = await pathReference.getDownloadURL();
    return downloadUri;
  };

  return (
    <Container>
      {product && (
        <BlobProvider document={<SpecificationExport product={product} specImageLink={specificationImageLink} />}>
          {({ url, loading }) => {
            const fileName = `${product.name}.pdf`;
            return (
              <HeaderButton disabled={loading}>
                <UnstyledLink href={url} download={fileName}>
                  PDF Export
                </UnstyledLink>
              </HeaderButton>
            );
          }}
        </BlobProvider>
      )}
      <CSVImportComponent />
      <DeleteButton onClick={() => setDeleteMiscModalVisible(true)}>
        <TrashCan20 color="#ffff" />
      </DeleteButton>
      <DeleteModal onClose={() => setDeleteMiscModalVisible(false)} isVisible={isDeleteMiscModalVisible} title="Delete product" onDelete={onProductDelete} />
    </Container>
  );
}

const DeleteButton = styled.div`
  height: 100%;
  width: 40px;
  margin-left: 5px;
  background-color: ${(props) => props.theme.colors.unabacusblack};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  > div,
  > button {
    margin-left: 12px;
  }
`;

const UnstyledLink = styled.a`
  text-decoration: none;
  color: unset;
`;
