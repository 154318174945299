import { DropDown } from "components/common/Dropdown";
import { useState } from "react";

import { TextInput } from "../../../../components/common/modals/TextInput";
import styled from "styled-components";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { ProductVendorsFirebase } from "services/external/firebase/models/ProductFirestore";
import { vendorStatus } from "core/data/vendorStatus";
import { ModalWrapper } from "components/common/modals/ModalWrapper";
import { ProductVendorsModel } from "services/domain/products/models/ProductModel";
import { Button } from "components/elements/buttons/Button";
import SecondaryButton from "components/common/buttons/SecondaryButton";
import PrimaryButton from "components/common/buttons/PrimaryButton";

interface Props {
  productVendor: ProductVendorsModel;
  productId: string;
  onSubmit: () => void;
}

export function UpdateProductVendorsModal({
  productVendor,
  productId,
}: Props): JSX.Element {
  const firebase = useFirebaseContext();
  const [vendor, setVendor] = useState<ProductVendorsFirebase>({
    name: productVendor.name,
    country: productVendor.country,
    vendorPrice: productVendor.vendorPrice,
    targetPrice: productVendor.transferPrice,
    itemWeight: productVendor.itemWeight,
    moq: productVendor.moq,
    status: productVendor.status,
    note: productVendor.note,
    isPreferred: productVendor.isPreferred,
  });
  const { id: productVendorId } = productVendor;

  const handleSubmit = async () => {
    if (!!vendor && !!vendor.name) {
      await firebase.ProductService.updateProductVendor(
        vendor,
        productId,
        productVendorId
      );
    }
  };

  const button = <Button type="submit">Edit</Button>;

  return (
    <ModalWrapper
      handleSubmit={handleSubmit}
      noFooter={true}
      button={button}
      title="Update Vendor"
    >
      <StyledModalContent>
        <DropDown
          value={productVendor.status}
          label="Current Status"
          name="currentStatus"
          onChange={(e: string) => setVendor({ ...vendor, status: e })}
          selectors={vendorStatus.map((status) => ({
            name: status,
            value: status,
          }))}
          black={true}
        />
        <TextInput
          label="Target price"
          handleChange={(e: string) =>
            setVendor({ ...vendor, targetPrice: parseFloat(e) })
          }
        />
        <TextInput
          label="Vendor price"
          handleChange={(e: string) =>
            setVendor({ ...vendor, vendorPrice: parseFloat(e) })
          }
          defaultValue={vendor.vendorPrice?.toString()}
        />
        <TextInput
          label="Item weight"
          handleChange={(e: string) => setVendor({ ...vendor, itemWeight: e })}
          defaultValue={vendor.itemWeight}
        />
        <TextInput
          label="MOQ"
          handleChange={(e: string) => setVendor({ ...vendor, moq: e })}
          defaultValue={vendor.moq}
        />
        <TextInput
          label="Note"
          handleChange={(e: string) => setVendor({ ...vendor, note: e })}
          defaultValue={vendor.note}
        />
        <Container>
          <SecondaryButton callback={undefined}>Delete</SecondaryButton>
          <PrimaryButton callback={handleSubmit}>Submit</PrimaryButton>
        </Container>
      </StyledModalContent>
    </ModalWrapper>
  );
}

const Container = styled.div`
  .secondary-button {
    width: auto;
    padding: 0px 10 px;
  }

  .primary-button {
    width: 100%;
  }

  height: 80px;
  width: "100%";
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;

  > button:first-child {
    margin-right: 1rem;
  }
`;

const StyledModalContent = styled.div`
  min-width: 300px;
  > * {
    margin-bottom: 10px;
  }
`;
