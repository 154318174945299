import React, { useEffect, useState } from "react";

export const Fade: React.FC<{ show: boolean }> = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return shouldRender ? (
    <div
      style={{ animation: `${show ? "fadein" : "fadeout"} 0.5s` }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};
