import React, { useState } from 'react';
import styled from 'styled-components';
import TabElement from './TabElement';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: Array<any>;
}

export default function TabList({ children }: Props): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>(children[0].props.title);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };
  return (
    <StyledTab className="tabs">
      <ol className="tab-list">
        {children.map((child) => {
          const { title } = child.props;

          return <TabElement key={title} activeTab={activeTab} title={title} onClick={onClickTabItem} />;
        })}
      </ol>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.title !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </StyledTab>
  );
}

const StyledTab = styled.div`
  width: 100%;

  .tab-list {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 1rem 2rem;
    :hover {
      cursor: pointer;
    }
  }

  .tab-content {
    transition: opacity 0.5s;
    padding: 3rem;
  }

  .tab-list-active {
    font-weight: bold;
    border-bottom: solid;
  }
`;
