import { useEffect, useState } from "react";
import { useFirebaseContext } from "core/contexts/FirebaseContext";

 interface UseGetProductTotalPriceModel {
  totalPrice: number;
  totalBaseMaterialPrice: number;
  totalPlatingPrice: number;
  totalLaborPrice: number;
  totalMiscPrice: number;
  totalMaterialPartPrice: number;
}

const initialPriceState: UseGetProductTotalPriceModel = {
  totalPrice: 0,
  totalPlatingPrice: 0,
  totalMiscPrice: 0,
  totalMaterialPartPrice: 0,
  totalLaborPrice: 0,
  totalBaseMaterialPrice: 0
}

export function useGetProductTotalPriceV2(
  id: string
): UseGetProductTotalPriceModel {
  const [price, setPrice] = useState<UseGetProductTotalPriceModel>(initialPriceState);
  const firebase = useFirebaseContext();

  useEffect(() => {
    const func = async () => {
      const unsubscribe = await firebase.getTotalPriceHook(id, d => {
        const { totalPrice, totalBaseMaterialPrice, totalPlatingPrice, totalMiscPrice, totalLaborPrice, totalMaterialPartPrice } = d;
        setPrice({
          totalPrice,
          totalBaseMaterialPrice,
          totalLaborPrice,
          totalMaterialPartPrice,
          totalMiscPrice,
          totalPlatingPrice
        });
      } );
      return unsubscribe;
    }
    const unsub =  func();
    return () => unsub;
}, []);
return price;
};
