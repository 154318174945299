import { useState } from 'react';

import styled from 'styled-components';
import { Container } from 'components/elements/Container';
import { Label } from 'components/elements/Label';
import { ProductVendorsModel } from 'services/domain/products/models/ProductModel';
import { Button } from 'components/elements/buttons/Button';
import { defaultIfNullOrEmpty } from 'core/utilities/string';
import { UpdateProductVendorsModal } from './UpdateProductVendorsModal';
import { HoverTextButton } from 'components/elements/buttons/HoverTextButton';
interface Prop {
  productId: string;
  productVendor: ProductVendorsModel;
  onPreferedVendorClick: (id?: string) => void;
}

export const ProductVendorsComponent = ({ productVendor, productId, onPreferedVendorClick }: Prop): JSX.Element => {
  const { name, id, country, itemWeight, status, moq, isPreferred, vendorPrice } = productVendor;
  const [showEdit, setShowEdit] = useState<boolean>(true);
  return (
    <StyledProductVendorComponent selected={isPreferred}>
      <h4>{name}</h4>
      <h5>{country}</h5>

      <hr />
      <InformationSection>
        <b>{status}</b>

        <Container>
          <Label black={true}>Vendor Price</Label>
          {defaultIfNullOrEmpty(vendorPrice?.toString(), '-')}$
        </Container>
        <Container>
          <Label black={true}>Item Weight</Label>
          {defaultIfNullOrEmpty(itemWeight?.toString(), '-')}
        </Container>
        <Container>
          <Label black={true}>MOQ</Label>
          {defaultIfNullOrEmpty(moq?.toString(), '-')}
        </Container>
      </InformationSection>

      <ActionBar>
        {!isPreferred ? (
          <Button variant="secondary" onClick={() => onPreferedVendorClick(id)} type="submit">
            Make Prefered Vendor
          </Button>
        ) : (
          <HoverTextButton text="Prefered vendor" hoverText="Remove prefered vendor" onClick={() => onPreferedVendorClick()} />
        )}
        <UpdateProductVendorsModal onSubmit={() => setShowEdit(false)} productVendor={productVendor} productId={productId} />
      </ActionBar>
    </StyledProductVendorComponent>
  );
};

const ActionBar = styled.span`
  display: flex;
  flex-direction: row;
  button:first-child,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 100%;
  }
  div {
    background-color: ${(prop) => prop.theme.colors.mainColorLight};
    color: ${(prop) => prop.theme.colors.mainColor};
  }
`;

const InformationSection = styled.div`
  > * {
    margin: 20px 0;
  }
`;

interface StyledProductVendorComponentProp {
  selected: boolean;
}

export const StyledProductVendorComponent = styled.div<StyledProductVendorComponentProp>`
  background: white;
  border: ${(prop) => `solid ${prop.selected ? prop.theme.colors.mainColor : prop.theme.colors.mainColorLight} 2px`};
  padding: 15px;
  max-width: auto;
  min-width: 285px;
  b {
    font-weight: 800;
  }
  h4 {
    font-weight: 400;
    font-size: 18px;
    padding-bottom: 5px;
  }

  h5 {
    font-weight: 400;
    font-size: 12px;
  }

  hr {
    border-top: 1px solid lightgray;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
