import React from "react";
import styled from "styled-components";



export default function Divider(): JSX.Element {
  return (
    <StyleDivider/>
  );
}


const StyleDivider = styled.div`
width: 100%;
border-top: 2px solid #d5d5d5;
`;
