import React from "react";
import styled from "styled-components";

interface Props {
  children: string;
  callback: () => void;
}

export default function SecondaryButton({
  children,
  callback,
}: Props): JSX.Element {
  return (
    <Container className="secondary-button" onClick={callback}>
      {children}
    </Container>
  );
}

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #37285d;
  background-color: #fff;
  border: 1px solid #37285d;
  width: 170px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
`;
