import { Label } from "components/elements/Label";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  handleChange: (param: string) => void;
  label: string;
  defaultValue?: string;
  placeholder?: string;
}

export function TextInput({
  handleChange,
  label,
  defaultValue,
  placeholder,
}: Props): JSX.Element {
  return (
    <div>
      <Label>{label}</Label>
      <Input
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value) }
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
    </div>
  );
}

const Input = styled.input`
  background-color: #f2f2f2;
  height: 30px;
  width: 100%;
  border: none;

  &:focus {
    outline: none;
  }
`;
