import DropDownCategory from "modules/category/components/table/header/DropdownCategory";
import { useFirebaseContext } from "core/contexts/FirebaseContext";
import { Layout } from "components/common/Layout";
import { HeaderLayout } from "components/common/HeaderLayout";
import { AddProductModal } from "modules/product/components/forms/addProduct";
import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import { ProductFirestore } from "services/external/firebase/models/ProductFirestore";
import { NewProductTable } from "modules/category/components/products/NewProductTable";
import styled from "styled-components";


export function CategoryPage(): JSX.Element {
  const [products] = useState<ProductFirestore[]>([]);
  const [categoryName, setCategoryName] = useState<string>();
  const { id } = useParams<{ id: string }>();

  const firebase = useFirebaseContext();

  useLayoutEffect(() => {
    if (id) {
      firebase.getCurrentCategoryName(id).then(function (name: string) {
        setCategoryName(name);
      });
    }
  }, [id]);

  return (
    <Layout>
      <HeaderLayout
        title={<DropDownCategory categoryName={categoryName} count={products.length} />}
        rightComponent={<AddProductModal />}
      />
      { <ContentLayout><NewProductTable queryData={(param) => firebase.searchProduct({...param, categoryId:id, limit: 15})} /></ContentLayout> }
    </Layout>
  );
}


const ContentLayout = styled.div`
  padding: 0 1rem;
  width: 100%;
`
