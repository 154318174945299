import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useFirebaseContext } from 'core/contexts/FirebaseContext';
import { useParams } from 'react-router';
import { DropDown } from 'components/common/Dropdown';
import styled from 'styled-components';
import { Container } from 'components/elements/Container';
import { Label } from 'components/elements/Label';
import { useGetProductTotalPrice } from 'core/hooks/firebase/products/useGetProductTotalPrice';
import { LaborDifficultyDefinition, DifficultyMethodDefinition, CalculationMethodType, CalculationMethodDefinition } from './CalculationDefinition';
import { ProductModel } from 'services/domain/products/models/ProductModel';
import { SimpleInputContainer } from 'components/common/SimpleInputContainer';

interface Prop {
  product: ProductModel;
}

export const LaborCalculationView = ({ product }: Prop): JSX.Element => {
  const difficultyMethods = DifficultyMethodDefinition;

  const firebase = useFirebaseContext();
  const { id } = useParams<{ id: string }>();
  const initialRender = useRef(true);

  const { totalLaborPrice } = useGetProductTotalPrice(id);
  const [selectedCalculationMethod, setSelectedCalculationMethod] = useState<CalculationMethodType>('percentage');
  const [customCost, setCustomCost] = useState<number>(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState<LaborDifficultyDefinition>(() => {
    return difficultyMethods.find(({ type }) => type == product.labor.type) || difficultyMethods[0];
  });

  useLayoutEffect(() => {
    if (!!product) {
      const productDifficulty = difficultyMethods.find(({ type }) => type == product.labor.type) || difficultyMethods[0];
      setSelectedDifficulty(productDifficulty);

      const productCalculationMethod = product.labor.calculationMethod || 'percentage';
      setSelectedCalculationMethod(productCalculationMethod);

      setCustomCost(product.labor.cost || 0);
    }
  }, [product?.productParts]);

  useEffect(() => {
    if (initialRender.current == true) {
      initialRender.current = false;
      return;
    }
    firebase.ProductService.updateLabor(id, selectedDifficulty.type, selectedCalculationMethod, customCost);
  }, [selectedDifficulty.id, selectedCalculationMethod, customCost]);

  return (
    <StyledLaborComponent>
      <h4>Labor Parameters</h4>
      <DropDown
        value={selectedCalculationMethod}
        label="Calculation method"
        name="calculationMethod"
        selectors={Object.entries(CalculationMethodDefinition).map(([_, value]) => ({
          name: value.displayName,
          value: value.id,
        }))}
        onChange={(val: any) => setSelectedCalculationMethod(val)}
        black={true}
      />
      <hr />
      {selectedCalculationMethod == 'percentage' && (
        <>
          <DropDown
            onChange={(e) => setSelectedDifficulty(difficultyMethods.find((difficulty) => difficulty.id === e))}
            value={selectedDifficulty.id}
            label="Difficulty"
            name="difficulty"
            selectors={difficultyMethods.map((difficulty) => ({
              name: difficulty.name,
              value: difficulty.id,
            }))}
            black={true}
          />
          <div className="subtitle">
            {selectedDifficulty.name} will represent xx% of the total cost (based on the Total of the differents Base materials too)
          </div>
          <Container>
            <Label black={true}>Labor Cost</Label>${totalLaborPrice.toFixed(2)}
          </Container>
        </>
      )}
      {selectedCalculationMethod == 'custom' && (
        <>
          <Container>
            <SimpleInputContainer
              pattern="^(\d*\.)?(\d+)?$"
              onInputChange={(val) => {
                console.log(`price change ${val} `);
                setCustomCost(parseFloat(val));
              }}
              type="text"
              name="laborCost"
              placeholder="..."
              label="Labor cost"
              value={customCost.toString()}
              black={true}
            />
          </Container>
        </>
      )}
    </StyledLaborComponent>
  );
};

export const StyledLaborComponent = styled.div`
  background: white;
  padding: 15px;
  max-width: auto;
  width: 290px;
  h4 {
    padding-bottom: 15px;
  }

  hr {
    border-top: 1px solid lightgray;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .subtitle {
    width: 260px;
    font-style: oblique;
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
